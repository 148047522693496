import React from 'react'

export default function SmartDefi() {
    return (
        <>
            <section className="our-values__area theme-bg-5 section-rounded-60 section-space">
                <div className="container">
                    <div className="row gy-50 align-items-center">
                        <div className="col-xxl-12 col-xl-5 col-lg-5">
                            <div className="our-values__content-wrapper wow fadeIn" data-wow-delay=".3s">
                                <div className="section__title-wrapper">
                                    <h2 className="section__title mb-25">What is Smart DeFi World ?
                                    </h2>
                                </div>
                                <p className="mb-0">Smart DeFi World takes banking to the next level by leveraging blockchain
                                    technology and the ethos of decentralization. It addresses the shortcomings
                                    of traditional banking while providing users with greater control over their
                                    finances.
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-12 col-xl-12 col-lg-12">
                            <div className="row g-5">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="our-values__item wow fadeIn min-height-430 " data-wow-delay=".4s">
                                        <div className="our-values__icon">
                                            <span>
                                                <img src="./assets/imgs/community.png" style={{ width: "50px" }} alt="" />
                                            </span>
                                        </div>
                                        <div className="our-values__content">
                                            <h3>Community Governance</h3>
                                            <p>Governed by its community of users, who can
                                                propose and vote on changes to the protocol, ensuring the platform aligns
                                                with the collective growths of users. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="our-values__item is-mainly-red wow fadeIn min-height-430" data-wow-delay=".5s">
                                        <div className="our-values__icon">
                                            <span>
                                                <img src="./assets/imgs/decentralized.png" style={{ width: "50px" }} alt="" />
                                            </span>
                                        </div>
                                        <div className="our-values__content">
                                            <h3>Decentralized</h3>
                                            <p>Operates on the Binance blockchain, eliminating the need for
                                                intermediaries such as banks or financial institutions. This results in greater
                                                transparency and reduced risk. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="our-values__item is-mainly-pink wow fadeIn min-height-430" data-wow-delay=".6s">
                                        <div className="our-values__icon">
                                            <span>
                                                <img src="./assets/imgs/security.png" style={{ width: "50px" }} alt="" />
                                            </span>
                                        </div>
                                        <div className="our-values__content">
                                            <h3>Security</h3>
                                            <p>Undergoes regular security audits by reputable third-party firms to
                                                identify and mitigate potential vulnerabilities. Blockchain technology
                                                safeguards user assets and transactions. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="our-values__item is-mainly-blue wow fadeIn" data-wow-delay=".7s">
                                        <div className="our-values__icon">
                                            <span>
                                                <img src="./assets/imgs/peer_to_peer.png" style={{ width: "50px" }} alt="" />
                                            </span>
                                        </div>
                                        <div className="our-values__content">
                                            <h3>Peer-to-Peer Financing</h3>
                                            <p>Allows users to deposit their assets, earn exclusive
                                                growth on their holdings, and receive various rewards. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="our-values__item is-mainly-blue wow fadeIn" data-wow-delay=".7s">
                                        <div className="our-values__icon">
                                            <span>
                                                <img src="./assets/imgs/exponential_benefits.png" style={{ width: "50px" }} alt="" />
                                            </span>
                                        </div>
                                        <div className="our-values__content">
                                            <h3>Exponential Benefits</h3>
                                            <p>Offers substantial benefits, including higher growth
                                                rates than traditional banks, referral rewards, ranking rewards, retirement
                                                income, and more.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
