import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div>
      <footer>
        <div className="footer__area theme-bg">
          <div className="container">
            <div className="footer__main-1 py-5">
              <div className=" d-flex justify-content-center">
                <div className="footer__logo">
                  <a href="/">
                    <img src="assets/imgs/smartdefi_logo.png" alt="image bnot found" />
                  </a>
                </div>
                {/* <div className="footer__content">
                  <div className="footer__social">
                    <a href="#"><i className="fa-brands fa-facebook" /></a>
                    <a href="#"><i className="fa-brands fa-twitter" /></a>
                    <a href="#"><i className="fa-brands fa-linkedin" /></a>
                    <a href="#"><i className="fa-brands fa-youtube" /></a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="footer__bottom">
                  <div className="footer__copyright">
                    <p>Copyright © 2024 <a href="#">Smart Defi World</a></p>
                  </div>
                  <div className="footer__conditions">
                    <ul>
                      <li><Link to="/privacy-policy" >Privacy Policy</Link></li>
                      <li><Link to="/terms-and-service">Terms & Service</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </div>
  )
}