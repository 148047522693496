import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Dashbordheader from "./Dashbordheader";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from "./RadialSeparators";
import { ProjectContext } from "../../ProjectContext";
import { Link } from "react-router-dom";
import axios from "axios";
import Remind from "./Remind";

export default function Dashboard() {
  const { fdpkg, rdpkg, copyaddress,
    account,
    balance,
    rate,
    smuser, dbbalance,
    toastSuccess,
    library,
    dbuser,
    tokenrate, formatAddress,
    stakcontract,
    toastError,
    getudata,
    settings,
    esigner,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)
  const [ARD, setARD] = useState(null);
  const [AFD, setAFD] = useState(null);


  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");

  const getActiveRD = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "r_deposits",
        submethod: "getpbyid",
        key: process.env.REACT_APP_KEY,
        address: account
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setARD(res.data.data);
      });
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "f_deposits",
        submethod: "getpbyid",
        key: process.env.REACT_APP_KEY,
        address: account
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setAFD(res.data.data);
      });
  }

  const onPremium = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    // if (dbuser !== null && dbuser.last_staking > amount) {
    //     toastError(`Stake ${dbuser.last_staking} or more`);
    //     settrdone(false);
    //     return;
    // }
    let contract = new library.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    let token_contract = new library.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
  );
  const allowance = await token_contract.allowance(
      account,
      settings.contract
  );

    var total_tokens = ARD.amount / tokenrate;
    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
      //   );
      try {
        var stk = await contract.Premium(
          account,
          ethers.utils.parseUnits(`${total_tokens}`, 8),
        );
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        toastSuccess("Transaction done");
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: dbuser.ref_address,
            hash: receipt.transactionHash,
            amount: ARD.amount,
            token: total_tokens,
            rate: tokenrate,
            isrd: true,
            pkg_id: ARD.pkg_id
          })
          .then((res) => {
            console.log("res", res.data);

            // var dedata = decryptData(res.data);
            if (res.data.error) {
              return "";
            }
          });
        getuserDB();
        getActiveRD()
        settrdone(false);
      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }
    if (Number(ethers.utils.parseUnits(`${allowance}`,8)) < total_tokens) {
      // if (false) {
      try {
          var approv = await token_contract.approve(
              settings.contract,
              ethers.utils.parseUnits("100000000",8)
          );
          const receipt = await approv.wait();
          console.log(receipt);
          toastSuccess("Token Approved");
          main();
      } catch (error) {
          console.log("error", error);
          toastError("Token not approved");
          settrdone(false);
          return;
      }
  } else {
      main();
  }

  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getActiveRD()
  }, [account])
  return (
    <div>
      <Dashbordheader />


      <main>
        <div className="style__guide-area section-space pt-1">
          <div className="container-fluid">
            <div className="row g-5 justify-content-center">
              <div className="col-xl-2 col-lg-2">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-lg-9">
                <div className="guide__widget-box">
                  <div id="colorPalette" className="elements__area">
                    <div className="wrapper">
                      <h3 className="style__guide-title mb-20">
                        Dashboard
                      </h3>
                      {dbuser !== null
                        ? dbuser.active_rank ?
                          <h5 className="blink_me">Congratulations You Achieved {dbuser.rank}!</h5>
                          : ''
                        : ""}
                      <br />
                      <h4 className="text-warning"> {dbuser !== null ? dbuser.next_rd.isWithinNext7Days ? dbuser.next_rd.isToday ? "Today is your due date" : `Next Due at ${dbuser.next_rd.futureDate}, ${dbuser.next_rd.daysLeft} days left` : '' : ""}</h4>
                      <br />
                      <div className="row g-5">
                        <div className="col-xl-8 col-lg-6 col-md-6">
                          <div className="price__item min-height wow fadeIn">
                            <div className="price__top">
                              <div className="price__title">
                                <h4>Your Account</h4>
                                <p className="mt-4 mb-0">Username: {dbuser ? dbuser.uname : ' -'}</p>
                              </div>
                              <div className="price__value">
                                <div className="row pb-0">
                                  <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                    <p className="mt-4 mb-0 text-blue">My Referral Code</p>
                                    <p className="text-break" onClick={() => copyaddress(account)}>{formatAddress(account)} <i className="fa fa-copy"></i></p>
                                    <button className="bd-btn" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}referral/${account}`)}>My Referral Code</button>
                                    {/* <p className="mt-4 mb-0 text-blue">Recommendation Address</p>
                                    <p className="text-break fs-4" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}referral/${account}`)}>{`${process.env.REACT_APP_LINK}referral/${account}`}  <i className="fa fa-copy"></i></p> */}

                                  </div>
                                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                    <p className="mt-4 mb-0 text-white">Agency</p>
                                    <p className={`mt-0 text-${dbuser !== null
                                      ? dbuser.agency ? "success" : "danger"
                                      : "danger"}`}>{dbuser !== null
                                        ? dbuser.agency ? "Active" : "Inactive"
                                        : "-"}</p>
                                    <p className="mt-4 mb-0 text-white">Nominee</p>
                                    {/* <p className="mt-0 text-success">Active</p> */}
                                    <p className={`mt-0 text-${dbuser !== null
                                      ? dbuser.nominee !== null ? "success" : "danger"
                                      : "danger"}`}>{dbuser !== null
                                        ? dbuser.nominee !== null ? "Active" : "Inactive"
                                        : "-"}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <div className="price__item min-height wow fadeIn">
                            <div className="price__top">
                              <div className="price__title">
                                <h4>Live Rate</h4>
                              </div>
                              <div className="price__value">
                                <strong className="fs-1">$ {tokenrate}</strong>
                              </div>
                            </div>
                            <div className="price__top">
                              <div className="price__title">
                                <h4>Current Position</h4>
                              </div>
                              <div className="price__value">
                                <strong className="fs-3 text-break">{dbuser !== null
                                  ? dbuser.rank
                                  : "-"} </strong>
                                <br />
                                {dbuser !== null
                                  ? dbuser.active_rank ?
                                    <p className="mt-0 text-success">Active</p>
                                    : <p className="mt-0 text-danger">Inactive</p>
                                  : "Active"}

                              </div>
                            </div>
                          </div>
                        </div>
                        {/* current RD */}
                        {ARD !== null ?
                          <div className="col-xl-12 col-lg-12 col-md-6">
                            <div className="price__item wow fadeIn">
                              <div className="row">
                                <div className="col-8">
                                  <div className="price__top">
                                    <div className="price__title">
                                      <h4>Regular Deposit</h4>
                                    </div>
                                    <div className="price__value">
                                      <strong>{ARD.amount}$</strong>
                                      <sub>/Months</sub>
                                      <p className="mt-4 mb-0 text-white">Maturity Date</p>
                                      <p className="mt-0">{ARD.end_date}</p>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          <h6 className="title mb--0">Tenure</h6>
                                          <p className="mt-0">{ARD.t_installments / 12} Years</p>
                                        </div>
                                        <div>
                                          <p className="mt-4 mb-0 text-white">Next Premium Date</p>
                                          <p className="mt-0">{ARD.next_emi_date}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {trdone ? (

                                    <img
                                      className=" bd-gradient-btn rounded-pill w-10"
                                      src="/assets/imgs/loader.gif"
                                      alt="loading"
                                      style={{ width: '100px' }}
                                    />

                                  ) : (
                                    <div className="header__btn-wrap">
                                      <button className="bd-gradient-btn rounded-pill" onClick={() => onPremium()}>Premium</button>
                                    </div>
                                  )}

                                </div>

                                <div className="col-3">
                                  <CircularProgressbarWithChildren
                                    value={ARD.installment * 100 / ARD.t_installments}
                                    text={`${ARD.installment}`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                      strokeLinecap: "butt",
                                      pathColor: "#ab4d92"
                                    })}
                                  >
                                    <RadialSeparators
                                      count={ARD.t_installments}
                                      style={{
                                        background: "#fff",
                                        width: "2px",
                                        // This needs to be equal to props.strokeWidth
                                        height: `${10}%`
                                      }}
                                    />
                                  </CircularProgressbarWithChildren>
                                </div>
                              </div>
                            </div>
                          </div> : ''}
                        {/* active fd */}
                        {AFD !== null ?
                          <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="price__item wow fadeIn">
                              <div className="row">
                                <div className="col-8">
                                  <div className="price__top">
                                    <div className="price__title">
                                      <h4>Fixed Deposit</h4>
                                    </div>
                                    <div className="price__value">
                                      <strong>{AFD.amount}$</strong>
                                      <sub></sub>
                                      <p className="mt-4 mb-0 text-white">Maturity Date</p>
                                      <p className="mt-0">{AFD.end_date}</p>
                                      <h6 className="title mb--0">Tenure</h6>
                                      <p className="mt-0">{AFD.days / 360} Years</p>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <CircularProgressbarWithChildren
                                    value={AFD.c_days * 100 / AFD.p_days}
                                    text={`${AFD.p_days - AFD.c_days}days`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                      strokeLinecap: "butt",
                                      pathColor: "#674ad9 "
                                    })}
                                  >
                                    {/* <RadialSeparators
                                      count={AFD.p_days}
                                      style={{
                                        background: "#fff",
                                        width: "2px",
                                        // This needs to be equal to props.strokeWidth
                                        height: `${10}%`
                                      }}
                                    /> */}
                                  </CircularProgressbarWithChildren>
                                </div>
                              </div>

                            </div>
                          </div> : ''}
                        <div className="col-12">
                          <section className="element-button">
                            <div className="price__item wow fadeIn">
                              <div className="container">
                                <div className="row">
                                  <div className="row ">
                                    <div className="col-xxl-12">
                                      <div className="section__title-wrapper text-cen text-center">
                                        <h2 className="section__title">Smart Finance</h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row ps-0 g-5 align-items-center justify-content-center">
                                    <div className="col-lg-3 text-center">
                                      <Link className="bd-btn" to="/stake">Deposit<span><i className="fa-regular fa-angle-right" /></span></Link>
                                    </div>
                                    {dbuser !== null
                                      ? dbuser.agency ? "" :
                                        <div className="col-lg-3 text-center">
                                          <Link className="bd-btn btn-secondary" to="/agency">Purchase Agency<span><i className="fa-regular fa-angle-right" /></span></Link>
                                        </div>
                                      : ""}
                                    <div className="col-lg-3 text-center">
                                      <a className="bd-btn btn-tertiary" href="https://smartbull.io/trade/SDC_USDT" target="_blank">Buy SDC<span><i className="fa-regular fa-angle-right" /></span></a>
                                    </div>
                                    <div className="col-lg-3 text-center">
                                      <button className="bd-btn btn-quaternary" onClick={() => handleShow()}>My Recommendation<span><i className="fa-regular fa-angle-right" /></span></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/required_leg.svg" className='side-icon me-3' alt="" />
                              {dbuser && dbuser.rank_id === "665962d87f385a4a24fae215" ?
                                "-"
                                :
                                <>
                                  <h5>${dbuser !== null
                                    ? dbuser.r_aleg
                                    : "0"}</h5>

                                </>
                              }
                            </div>
                            <div className="">
                              <strong>Required Team A</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/required_leg.svg" className='side-icon me-3' alt="" />
                              {dbuser && dbuser.rank_id === "665962d87f385a4a24fae215" ?
                                "-"
                                :
                                <>

                                  <h5>${dbuser !== null
                                    ? dbuser.r_bleg
                                    : "0"}</h5>
                                </>
                              }
                            </div>
                            <div className="">
                              <strong>Required Team B</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/required_leg.svg" className='side-icon me-3' alt="" />
                              {dbuser && dbuser.rank_id === "665962d87f385a4a24fae215" ?
                                "-"
                                :
                                <>
                                  <h5>${dbuser !== null
                                    ? dbuser.r_cleg
                                    : "0"}</h5>
                                </>}
                            </div>
                            <div className="">
                              <strong>Required Team C</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/required_leg.svg" className='side-icon me-3' alt="" />
                              {dbuser && dbuser.rank_id === "665962d87f385a4a24fae215" ?
                                "-"
                                :
                                <>
                                  <h5>${dbuser !== null
                                    ? dbuser.r_oleg
                                    : "0"}</h5>
                                </>}
                            </div>
                            <div className="">
                              <strong>Required Team O.</strong>
                            </div>
                          </div>
                        </div>
                        <h3>Your statistics</h3>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/investment_1.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.t_staking
                                : "0"}</h5>
                            </div>
                            <div className="ment">
                              <strong>Investment</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/your_rd.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.t_rd
                                : "0"}</h5>
                            </div>
                            <div className="">
                              <strong>Your RD</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/your_fd.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.t_fd
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Your FD</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/team_rd.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.rd_team_business
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Team RD</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/team_fd.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.fd_team_business
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Team FD</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/rd_business.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.rd_team_business
                                : "0"}</h5>
                            </div>
                            <div className="">
                              <strong>RD Buisness</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/fd_business.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.fd_team_business
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>FD Buisness</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/total_business.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.team_business
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Total Business</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/leg.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.aleg
                                : "0"}</h5>
                            </div>
                            <div className="">
                              <strong>Team A Business</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/leg.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.bleg
                                : "0"}</h5>
                            </div>
                            <div className="">
                              <strong>Team B Business</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/leg.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.cleg
                                : "0"}</h5>
                            </div>
                            <div className="">
                              <strong>Team C Business</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/leg.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.oleg
                                : "0"}</h5>
                            </div>
                            <div className="">
                              <strong>Team O. Business</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/level_reward_1.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.level_inc
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Level Reward</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/position_reward_1.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.rank_inc
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Position Reward</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/retirement_reward_1.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.retirement_inc
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Retirement Reward</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/retirement_reward_1.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.bonus_reward
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Bonus Reward</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/next_position.svg" className='side-icon me-3' alt="" />
                              <h5>{dbuser !== null
                                ? dbuser.next_rank
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Next Position</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/pending_reward.svg" className='side-icon me-3' alt="" />
                              <h5>${dbbalance}</h5>
                            </div>
                            <div className="">
                              <strong>Pending Reward</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                          <div className="price__item  fadeIn">
                            <div className="priceop d-flex">
                              <img src="./assets/imgs/claimed_reward.svg" className='side-icon me-3' alt="" />
                              <h5>${dbuser !== null
                                ? dbuser.t_withdrawal
                                : "-"}</h5>
                            </div>
                            <div className="">
                              <strong>Claimed Reward</strong>
                            </div>
                          </div>
                        </div>

                        <Remind />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {show
          ?
          <>
            <div className="modal d-block">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header d-flex justify-content-between">
                    <h5 className="modal-title text-black" id="exampleModalLiveLabel">My Recommendation</h5>
                    <button type="button" className="btn-close" onClick={handleClose} />
                  </div>
                  <div className="modal-body">
                    <input
                      className="contact__input-box mb-15 mb-20"
                      type="text"
                      placeholder="Enter Referral Address"
                      value={dbuser !== null
                        ? dbuser.ref_address
                        : "-"}
                      onClick={() => copyaddress(dbuser !== null
                        ? dbuser.ref_address
                        : "-")}
                    />
                    <i className="fa fa-copy copy" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-backdrop fade show"></div>
          </>
          :
          ""
        }
      </main >
    </div >
  );
}
