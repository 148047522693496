import React, { useState } from 'react'
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Rd from './Rd';
import FD from './FD';

export default function Calculator() {
    const [showRd, setShowRd] = useState(true);
    const [showFd, setShowFd] = useState(false);

    const toggleRd = () => {
        setShowRd(true);
        setShowFd(false);
    };

    const toggleFd = () => {
        setShowRd(false);
        setShowFd(true);
    };
    return (
        <>
            <section className="loan__area section-space-bottom" id='Calculator'>
                <div className="container">
                    <div className="loan__wrapper wow fadeInUp" data-wow-delay=".3s">
                        <div className="loan__form-wrapper pe-0">
                            <div className="loan__from-title">
                                <h3 className='text-center'>RD/FD Calculator</h3>
                            </div>

                            <div className='d-flex justify-content-center '>
                                <div className="mb-5 bd-gradient-btn rounded-pill">
                                    <button className={showRd ? "active-text" : ""} onClick={toggleRd}>RD Calculator</button>
                                    <button className={showFd ? "active-text" : ""} onClick={toggleFd}>FD Calculator</button>
                                </div>
                            </div>
                            <br />
                            <div className={showRd ? "d-block" : 'd-none'}>
                                <Rd />
                            </div>
                            <div className={showFd ? "d-block" : 'd-none'}>
                                <FD />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
