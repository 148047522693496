import React from 'react'

export default function Exploremudrex() {
  return (
    <div>
      <section>
        <div className="container">
          <h2 className='text-center banner__content-wrapper '>Experience the<span className="gradient-text-1 mx-3"> Decentralized Banking </span></h2>
        </div>
        <div className="container bg-img-1">
        </div>
        <br />
      </section>
    </div>
  )
}
