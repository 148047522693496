import React from "react";
import Sidebar from "./Sidebar";
import Dashbordheader from "./Dashbordheader";
export default function Nominee() {
  return (
    <div>
      <Dashbordheader />
      <main>
        <div className="style__guide-area section-space pt-1">
          <div className="container-fluid">
            <div className="row g-5 justify-content-center">
              <div className="col-xl-2 col-lg-2">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-lg-9">
                <div className="guide__widget-box">
                  <div id="colorPalette" className="elements__area">
                    <div className="wrapper text-center">
                      <h3 className="style__guide-title mb-20">
                        Nominee Details
                      </h3>

                      <div className="row g-5 justify-content-center">
                        <div className="col-xl-8 col-lg-6 col-md-6">
                          <div className="price__item wow fadeIn">
                            <div className="price__top">
                              <div className="price__title">
                                <h4>Nominee</h4>
                              </div>
                              <div className="price__value">
                                <div className="row pb-0 ">
                                  <div className="col-8">
                                    <p className="mt-4 mb-0 text-white">Your Address</p>
                                    <p className="text-break">0xDBedb4BE0...c09286508B8A <i className="fa fa-copy"></i></p>
                                    <p className="mt-4 mb-0 text-white">Nominee Address</p>
                                    <p className="text-break">0xDBedb4BE0...c09286508B8A <i className="fa fa-copy"></i></p>
                                  </div>
                                  <div className="col-4">
                                    <p className="mt-4 mb-0 text-white">Nominee</p>
                                    <p className="mt-0 text-success">Active</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
    </div >
  );
}
