import logo from "./logo.svg";
import "./App.css";
import Index from "./Components/Home/Index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import Staking from "./Components/Dashboard/Staking";
import Withdrawal from "./Components/Dashboard/Withdrawal";
import Profile from "./Components/Dashboard/Profile";
import About from "./Components/About/About";
import Stake from "./Components/Dashboard/Stake";
import Agency from "./Components/Dashboard/Agency";
import Tbale from "./Components/Dashboard/Tbale";
import Nominee from "./Components/Dashboard/Nominee";
import LevelReward from "./Components/Dashboard/LevelReward";
import Team from "./Components/Dashboard/Team";
import RankReward from "./Components/Dashboard/RankReward";
import RetirementReward from "./Components/Dashboard/RetirementReward";
import AgencyBuy from "./Components/Dashboard/AgencyBuy";
import CalculaterDash from "./Components/Dashboard/CalculaterDash";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsandService from "./Components/TermsandService";
import Transparency from "./Components/Transparency";
import ReferralRedirect from "./Components/Dashboard/ReferralRedirect";
import Tree from "./Components/Dashboard/Tree";
import TreeView from "./Components/Dashboard/Tree";
import Overview from "./Components/Dashboard/Overview";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/withdrawal" element={<Withdrawal />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/about" element={<About />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/stake/:link_ref_address" element={<Stake />} />
          <Route path="/referral/:link_ref_address" element={<ReferralRedirect />} />
          <Route path="/agency" element={<Agency />} />
          <Route path="/agency-details" element={<AgencyBuy />} />
          <Route path="/table" element={<Tbale />} />
          <Route path="/level-reward" element={<LevelReward />} />
          <Route path="/rank-reward" element={<RankReward />} />
          <Route path="/calculater" element={<CalculaterDash />} />
          <Route path="/retirement-reward" element={<RetirementReward />} />
          <Route path="/team" element={<Team />} />
          <Route path="/tree" element={<TreeView />} />
          <Route path="/nominee" element={<Nominee />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-service" element={<TermsandService />} />
          <Route path="/transparency" element={<Transparency />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;