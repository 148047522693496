import React, { useContext, useEffect, useState } from 'react'
import table from './table.css';
import Dashbordheader from './Dashbordheader';
import Sidebar from './Sidebar';
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Coman/Pagination';

export default function LevelReward() {
  const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "level",
        submethod: "getbyid",
        // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);


  return (
    <>
      <div>
        <Dashbordheader />
        <main>
          <div className="style__guide-area section-space">
            <div className="container-fluid">
              <div className="row g-5 justify-content-center">
                <div className="col-xl-2 col-lg-4">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="row justify-content-center wow fadeInUp" data-wow-delay=".6s">
                    <div className="col-xxl-12">
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                          <main>
                            <div className="container mt-4">
                              <div className="card">
                                <div className="row">
                                  {/* Grid column */}
                                  <div className="col-md-12">
                                    <h2 className="py-3 text-center font-bold font-up blue-text">Level Reward</h2>
                                  </div>
                                  {/* Grid column */}
                                </div>
                                <div className="card-body">
                                  {/* Grid row */}
                                  {/* Grid row */}
                                  {/*Table*/}
                                  <table className="table table-hover table-responsive mb-0">
                                    {/*Table head*/}
                                    <thead>
                                      <tr>
                                        <th scope="row"> # </th>
                                        <th className="th-lg"> From</th>
                                        <th className="th-lg"> Level</th>
                                        <th className="th-lg"> Staking</th>
                                        <th className="th-lg"> Reward</th>
                                        <th className="th-lg"> Status</th>
                                        <th className="th-lg"> Time</th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                      </tr>
                                      {!isLoading ? (
                                        !data.length ? (
                                          <tr className="text-center">
                                            <td className="text-center" colSpan={7}>
                                              <span className="w-100">No data found</span>
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {data.map((ele, i) => {
                                        return (
                                          <tr>
                                            <td >

                                              {ele.id}
                                            </td>
                                            <td >
                                              <div >
                                                <span

                                                  onClick={() => copyaddress(ele.from)}
                                                >
                                                  Username:{ele.uname}
                                                  <br />
                                                  {formatAddress(ele.from)}{" "}
                                                  <i className="fa fa-copy"></i>
                                                </span>
                                              </div>
                                            </td>
                                            <td >
                                              {ele.level}
                                            </td>
                                            <td >
                                              $ {ele.staking}
                                            </td>
                                            <td >

                                              $ {ele.income} ({ele.income_per} %)
                                            </td>

                                            <td >
                                              {ele.flushed ? "Flushed" : ""}
                                            </td>
                                            <td >

                                              {ele.datetime}
                                            </td>
                                          </tr>
                                        );
                                      })}

                                    </tbody>
                                    {/*Table body*/}
                                  </table>
                                  {/*Bottom Table UI*/}
                                  <Pagination
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    pages={pages}
                                  />
                                  {/*Bottom Table UI*/}
                                </div>
                              </div>
                            </div>
                          </main>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
