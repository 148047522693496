import React from 'react'

export default function Partners() {
  return (
    <div>
      <section className="service__area section-is-rounded theme-bg-1 section-space">
        <div className="container">
          <div className="banner__content-wrapper  p-relative z-index-11">
            {/* <span className="banner__sbutitle uppercase">Explore finwise now</span> */}
            <h2 >Proud ecosystem partners of Smart Digital Connect
            </h2>
          </div>
          <div className="row gy-5">
            <div className="col-12">
              <div className="service__item-2 is-brick-red wow fadeInUp" data-wow-delay="1s">
                <div className="service__icon-2">
                  <img src="assets/imgs/sdc01.svg" alt="image not found" className='me-20 ' />
                </div>
                <div className="service__content-2">
                  <h3>Smart Digital Connect Token</h3>
                  <p>SDC token is the native currency of Smart Digital Connect ecosystem. The
                    token is launched to empower a variety of utilities within the SDC
                    ecosystem. It is a decentralized currency that can be sent, received or stored
                    independently in crypto wallets.
                  </p>
                  <p>
                    Smart DeFi World will use SDC token for accepting deposits and distributing
                    incentives to users. It will process all the decentralized banking transactions
                    in SDC token.</p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="service__item-2 is-brick-red wow fadeInUp" data-wow-delay="1s">
                <div className="service__icon-2">
                  <img src="assets/imgs/smart_bull.svg" alt="image not found" className='me-20 ' />
                </div>
                <div className="service__content-2">
                  <h3>Smart Bull Exchange</h3>
                  <p>Smart-Bull is a crypto exchange facilitating the trading of hundreds of cryptocurrencies with SDC and other assets.It is the native application of Smart Digital Connect ecosystem which serves as a major community onboarding platform. Users can trade SDC with thousands of other assets like BTC, ETH, USDT and others seamlessly at low fees.</p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="service__item-2 is-brick-red wow fadeInUp" data-wow-delay="1s">
                <div className="service__icon-2">
                  <img src="assets/imgs/sd_200_60.png" alt="image not found" className='me-20 ' />
                </div>
                <div className="service__content-2">
                  <h3>Smart DeFi World</h3>
                  <p>Smart DeFi World is a novel decentralized banking protocol that reshapes the way traditional banking functions. It leverages smart contracts and blockchain technology to enable peer-to-peer financing. Built on the principles of transparency, security, and global accessibility – Smart DeFi World provides a decentralized platform for users to access a wide range of decentralized banking functionalities such as lending, borrowing, saving, and investing. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
