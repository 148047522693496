import React, { useContext } from 'react'
import { ProjectContext } from '../../ProjectContext'

export default function Statustics() {
    const { paraBtoken, settings, tokenrate } = useContext(ProjectContext)

    return (
        <>
            <section className="revolution__area section-space-top section-space-bottom wow fadeIn" data-wow-delay=".6s">
                <div className="container">
                    <div className="revolution__wrapper">
                        <div className="revolution__shape" />
                        <div className="row gy-5 align-items-center">
                            <div className="col-xxl-5 col-xl-6 col-lg-6">
                                <div className="revolution__intro section__title-space">
                                    <h3>Revolutionizing Banking</h3>
                                </div>
                            </div>
                        </div>
                        <div className="revolution__grid">
                            <div className="revolution__item text-center">
                                <div className="revolution__content">
                                    <div className="revolution__number">
                                        <h1><span className="counter">{settings ? settings.token_holders : 0}</span>+</h1>
                                    </div>
                                    <p className='fs-1'>Holders</p>
                                </div>
                            </div>
                            <div className="revolution__item text-center is-mainly-red">
                                <div className="revolution__content">
                                    <div className="revolution__number">
                                        <h1><span className="counter">{settings ? settings.transfers : 0}</span>+</h1>
                                    </div>
                                    <p className='fs-1'>Transfers</p>
                                </div>
                            </div>
                            <div className="revolution__item text-center is-mainly-blue">
                                <div className="revolution__content">
                                    <div className="revolution__number">
                                        <h1><span className="counter">$ {tokenrate}</span></h1>
                                    </div>
                                    <p className='fs-1'>SDC Rate
                                    </p>
                                </div>
                            </div>
                            <div className="revolution__item text-center is-mainly-pink">
                                <div className="revolution__content">
                                    <div className="revolution__number">
                                        <h1><span className="counter">$  {Math.trunc((paraBtoken * tokenrate) / 1000)}</span>K+</h1>
                                    </div>
                                    <p className='fs-1'>TVL</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
