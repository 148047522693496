import React from 'react'

export default function RdFd() {
    return (
        <>
            <section className="pricing__area theme-bg-1 section-space">
                <div className="container">
                    <div className="row section__title-space justify-content-center">
                        <div className="col-xxl-10 col-xl-10 col-lg-10">
                            <div className="section__title-wrapper text-center">
                                <h3 className="section__title">Choose Fixed Deposit (FD) or Regular Deposit (RD) as per your
                                    convenience
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-5 justify-content-center">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                            <div className="price__item wow fadeIn" data-wow-delay=".3s">
                                <div className="price__top">
                                    <div className="price__title">
                                        <h4>Fixed Deposit (FD) 	</h4>
                                    </div>
                                </div>
                                <div className="info__list mb-35">
                                    <ul>
                                        <li>One-time 
                                            investment</li>
                                        <li>Rewards are a percentage of the
                                            deposit amount.</li>
                                        <li>Fixed deposits are less flexible but
                                            provide higher rewards.</li>
                                    </ul>
                                </div>
                                <div className="price__btn">
                                    <a className="pricing__btn uppercase w-100" href="#">Get Started<span><i className="fa-regular fa-angle-right" /></span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                            <div className="price__item is-mainly-red wow fadeIn" data-wow-delay=".5s">
                                <div className="price__top">
                                    <div className="price__title">
                                        <h4>Regular Deposit (RD) </h4>
                                    </div>
                                </div>
                                <div className="info__list mb-35">
                                    <ul>
                                        <li>Multiple monthly Premiums</li>
                                        <li>Rewards are in form of additional
                                            Premiums.</li>
                                        <li>Regular deposits offer more
                                            flexibility with periodic payments.
                                        </li>
                                    </ul>
                                </div>
                                <div className="price__btn">
                                    <a className="pricing__btn uppercase w-100 border-red" href="#">Get Started<span><i className="fa-regular fa-angle-right" /></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
