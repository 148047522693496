import React from "react";
import Marquee from "react-fast-marquee";

export default function Homeslider() {
  return (
    <>
      <div >
        <div className="brand__inner style-2">
          <div className="container">
            <Marquee>
              {/* <img src="assets/imgs/smart_bull.png" style={{width:"100px"}} alt="" /> */}
              <div className="d-flex justify-content-between">
                <img src="assets/imgs/smart_bull.png" className="mx-3" alt="" />
                <img src="assets/imgs/trustwallet.png" className="mx-3" alt="" />
                <img src="assets/imgs/coingecko.png" className="mx-3" alt="" />
                <img src="assets/imgs/beincrypto.png" className="mx-3" alt="" />
                <img src="assets/imgs/trustwallet.png" className="mx-3" alt="" />
                <img src="assets/imgs/metamask.png" className="mx-3" alt="" />
                <img src="assets/imgs/sdc1.png" className="mx-3" alt="" />
                <img src="assets/imgs/bscscan.png" className="mx-3" alt="" />
                <img src="assets/imgs/yahoo.png" className="mx-3" alt="" />
                <img src="assets/imgs/blockfolio.png" className="mx-3" alt="" />
                <img src="assets/imgs/cryptopotato.png" className="mx-3" alt="" />
                <img src="assets/imgs/dropstab.png" className="mx-3" alt="" />
              </div>
            </Marquee>
          </div>
        </div>

      </div>
    </>
  );
}
