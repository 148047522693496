import React, { useContext, useEffect, useState } from 'react'
import Dashbordheader from './Dashbordheader'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import axios from 'axios'

export default function Profile() {
    const { dbuser, account, toastError, getuserDB, toastSuccess, ethers, esigner, library, connectMetamask, settings, copyaddress, formatAddress } = useContext(ProjectContext)
    const [fname, setfname] = useState(null);
    const [lname, setlname] = useState(null);
    const [email, setemail] = useState(null);
    const [nominee, setnominee] = useState(null);
    const [uname, setuname] = useState(null);

    const [trdone, settrdone] = useState(false);
    const [hash, sethash] = useState("");

    const updateProfile = async () => {
        if (!account) {
            return
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "agency",
                submethod: "update",
                address: account,
                key: process.env.REACT_APP_KEY,
                fname: fname,
                uname: uname,
                lname: lname,
                email: email
            })
            .then((res) => {
                if (res.data.error) {
                    toastError(res.data.error)
                    return "";
                }
                toastSuccess(res.data.data)
            });

    }
    const onAddNominee = async () => {
        settrdone(true);
        sethash("");
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!nominee) {
            toastError("Enter nominee address");
            settrdone(false);
            return;
        }
        let contract = new library.Contract(
            settings.contract,
            JSON.parse(settings.contract_abi),
            esigner
        );

        try {
            var stk = await contract.addnominee(
                nominee
            );
            const receipt = await stk.wait();
            sethash(receipt.transactionHash);
            // sethash(randomHash);
            toastSuccess("Transaction done");
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "nominee",
                    submethod: "insert",
                    key: process.env.REACT_APP_KEY,
                    address: account,
                    hash: receipt.transactionHash,
                    nominee: nominee,
                })
                .then((res) => {
                    // var dedata = decryptData(res.data);
                    if (res.data.error) {
                        return "";
                    }
                });
            getuserDB();
            settrdone(false);
        } catch (error) {
            settrdone(false);

            const regex = /"message":"([^"]*)"/g;
            const matches = [...error.message.matchAll(regex)];

            const secondMessage = matches.length >= 2 ? matches[1][1] : null;
            const modifiedErrorMessage = secondMessage.replace(/^execution reverted:\s*/, "");

            toastError(modifiedErrorMessage);

            return;
        }
    };

    useEffect(() => {
        if (dbuser !== null) {
            setlname(dbuser.last_name)
            setfname(dbuser.first_name)
            setemail(dbuser.email)
            setnominee(dbuser.email)
            setuname(dbuser.uname)
        }
    }, [dbuser])
    return (
        <>
            <div>
                <Dashbordheader />
                <main>
                    <div className="style__guide-area section-space">
                        <div className="container-fluid">
                            <div className="row g-5 justify-content-center">
                                <div className="col-xl-2 col-lg-4">
                                    <Sidebar />
                                </div>
                                <div className="col-xl-10 col-lg-8">
                                    <div>
                                        <h3 class="style__guide-title mb-20 text-center">Profile</h3>
                                        <div className="row g-5 justify-content-center">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div
                                                    className="price__item wow fadeIn"
                                                >
                                                    <div className="price__top">
                                                        <div className="style__guide-title mb-4">
                                                            <div>
                                                                <h4 className='mb-3'>Username</h4>
                                                                <div className="contact__input">
                                                                    <input
                                                                        name="cSDCanyname"
                                                                        id="lcname"
                                                                        type="text"
                                                                        placeholder="Enter username"
                                                                        value={uname}
                                                                        onChange={(e) => setuname(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="style__guide-title mb-4">
                                                            <div>
                                                                <h4 className='mb-3'>First Name</h4>
                                                                <div className="contact__input">
                                                                    <input
                                                                        name="cSDCanyname"
                                                                        id="lcname"
                                                                        type="text"
                                                                        placeholder="Enter  First Name"
                                                                        value={fname}
                                                                        onChange={(e) => setfname(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="style__guide-title mb-4">
                                                            <div>
                                                                <h4 className='mb-3'>Last Name</h4>
                                                                <div className="contact__input">
                                                                    <input
                                                                        name="cSDCanyname"
                                                                        id="fcname"
                                                                        type="text"
                                                                        placeholder="Enter Last Name"
                                                                        value={lname}
                                                                        onChange={(e) => setlname(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="style__guide-title mb-4">
                                                            <div>
                                                                <h4 className='mb-3'>Email</h4>
                                                                <div className="contact__input">
                                                                    <input
                                                                        name="cSDCanyname"
                                                                        id="fcname"
                                                                        type="text"
                                                                        placeholder="Enter Email"
                                                                        value={email}
                                                                        onChange={(e) => setemail(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="style__guide-title mb-4">
                                                            <div>
                                                                <h4 className='mb-3'>Address</h4>
                                                                <div className="contact__input">
                                                                    <input
                                                                        name="cSDCanyname"
                                                                        id="cdfname"
                                                                        type="text"
                                                                        placeholder="Enter Address"
                                                                        value={account}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="style__guide-title mb-4">
                                                            <h4 className='mb-4'>Nominee Address</h4>
                                                            <footer>
                                                                <section className="footer__area-common p-0">
                                                                    <div className="footer__newsletter">
                                                                        <div className="footer__newsletter-from mb-25">
                                                                            <div className="footer__newsletter-input p-relative">
                                                                                {dbuser !== null ? dbuser.nominee ?
                                                                                    <input type="text" value={dbuser.nominee} />
                                                                                    :
                                                                                    <>
                                                                                        <input type="text" placeholder="Enter Nominee" onChange={(e) => setnominee(e.target.value)} />
                                                                                        {trdone ? (
                                                                                            <img
                                                                                                className="btn-sm bd-gradient-btn rounded-pill w-10"
                                                                                                src="/assets/imgs/loader.gif"
                                                                                                alt="loading"
                                                                                                style={{ width: '100px' }}
                                                                                            />
                                                                                        ) : (
                                                                                            <div className="header__btn-wrap btn-sm">
                                                                                                <button className="bd-gradient-btn rounded-pill btn-sm" onClick={() => onAddNominee()}>Add Nominee</button>
                                                                                            </div>
                                                                                        )}
                                                                                        {/* <button className="footer__round-btn " title='Click here to add nominee address' onClick={() => onAddNominee()}><i className="fa-regular fa-check" />Add</button> */}
                                                                                    </> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </footer>
                                                        </div>
                                                        <br />
                                                        <hr />
                                                        <br />
                                                        <div className="d-flex justify-content-center">
                                                            <button className="bd-gradient-btn btn-xs " onClick={() => updateProfile()}>
                                                                Save Changes
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
