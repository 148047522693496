import React from 'react'
import Header from './Coman/Header'
import Footer from './Coman/Footer'
import { Link } from 'react-router-dom'

export default function Transparency() {
    return (
        <>
            <Header />
            <main>
                <div className="style__guide-area section-space">
                    <div className="container-fluid">
                        <div className="row g-5 justify-content-center">
                            <div className="col-xl-9 col-lg-8">
                                <div className="row justify-content-center wow fadeInUp" data-wow-delay=".6s">
                                    <div className="col-xxl-12">
                                        <div className="container my-5">
                                            <div className="card">
                                                <div className="row">
                                                    {/* Grid column */}
                                                    <div className="col-md-12">
                                                        <h2 className="py-3 text-center font-bold font-up blue-text">Transparency</h2>
                                                    </div>
                                                    {/* Grid column */}
                                                </div>
                                                <div className="card-body">
                                                    {/* Grid row */}
                                                    {/* Grid row */}
                                                    {/*Table*/}
                                                    <table className="table table-hover table-responsive mb-0">
                                                        {/*Table head*/}
                                                        <thead>
                                                            <tr>
                                                                <th scope="row">#</th>
                                                                <th className="th-lg"><a>Contract Name</a></th>
                                                                <th className="th-lg"><a>Contract Address</a></th>
                                                            </tr>
                                                        </thead>
                                                        {/*Table head*/}
                                                        {/*Table body*/}
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>Smart Defi World</td>
                                                                <td><Link to="https://bscscan.com/address/0x95d9e81f9259badc67bad335766648c66eef6efb#code" target='_blank'>0x95d9e81f9259badc67bad335766648c66eef6efb</Link></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">2</th>
                                                                <td>Smart Defi World Managment</td>
                                                                <td><Link to="https://bscscan.com/address/0xf8244823cffacc1b207829032a6d1013de9ac73d#code" target='_blank'>0xf8244823cffacc1b207829032a6d1013de9ac73d</Link></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">2</th>
                                                                <td>Para Banking</td>
                                                                <td><Link to="https://bscscan.com/address/0xb539f98bc677b68316d082996c024c2178cdc720#code" target='_blank'>0xb539f98bc677b68316d082996c024c2178cdc720</Link></td>
                                                            </tr>
                                                        </tbody>
                                                        {/*Table body*/}
                                                    </table>
                                                    {/*Bottom Table UI*/}
                                                    {/*Bottom Table UI*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}
