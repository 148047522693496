import React, { useContext, useEffect, useState } from 'react'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Coman/Pagination';

export default function StakingRD() {
  const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "r_deposits",
        submethod: "getbyid",
        // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        console.log("vvsdf", res.data);
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);


  return (
    <main>
      <div className="container mt-4">
        <div className="card">
          <div className="row">
            {/* Grid column */}
            <div className="col-md-12">
              <h2 className="py-3 text-center font-bold font-up blue-text">RD Investment</h2>
            </div>
            {/* Grid column */}
          </div>
          <div className="card-body">
            <table className="table table-hover table-responsive mb-0">
              <thead>
                <tr>
                  <th scope="row">#</th>
                  <th className="th-lg">Term</th>
                  <th className="th-lg">Premium</th>
                  <th className="th-lg">Total Premium</th>
                  <th className="th-lg">Reward Premium</th>
                  <th className="th-lg">Pending Premium</th>
                  <th className="th-lg">Next Premium Date</th>
                  <th className="th-lg">Start Date</th>
                  <th className="th-lg">Maturity Date</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  {isLoading ? "Data is loading" : ""}
                </tr>
                {!isLoading ? (
                  !data.length ? (
                    <tr className="text-center">
                      <td className="text-center" colSpan={9}>
                        <span className="w-100">No data found</span>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {data.map((ele, i) => {
                  return (
                    <tr>
                      <td >
                        {ele.id}
                      </td>
                      <td >
                        {ele.days} Days
                      </td>
                      <td >
                        $ {ele.amount}
                      </td>
                      <td >
                        {ele.t_installments} Months
                      </td>

                      <td >
                        {ele.reward} Months
                      </td>
                      <td >
                        {ele.t_installments - ele.installment} Months
                      </td>
                      <td >
                        {ele.next_emi_date}
                      </td>
                      <td >
                        {ele.datetime}
                      </td>
                      <td >
                        {ele.end_date}
                      </td>
                    </tr>
                  );
                })}

              </tbody>
              {/*Table body*/}
            </table>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pages={pages}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
