import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

export default function ReferralRedirect() {
    const { link_ref_address } = useParams();
    var navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate(`/stake/${link_ref_address}`)
        }, 2000);
    }, [link_ref_address])
    return (
        <>
        </>
    )
}
