import React, { useContext, useEffect, useState } from 'react'
import table from './table.css';
import Dashbordheader from './Dashbordheader';
import Sidebar from './Sidebar';
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import StakingRD from './StakingRD';
import StakingFD from './StakingFD';

export default function Staking() {
  const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "level",
        submethod: "getbyid",
        // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);


  return (
    <div>
      <Dashbordheader />
      <main>
        <div className="style__guide-area section-space">
          <div className="container-fluid">
            <div className="row g-5 justify-content-center">
              <div className="col-xl-2 col-lg-4">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="row justify-content-center wow fadeInUp" data-wow-delay=".6s">
                  <div className="col-xxl-12">
                    <div className="solution__tab bd-tab">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"><span>01</span>RD Investment</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><span>02</span>FD Investment</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xxl-12">
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                        <StakingRD />
                      </div>
                      <div className="tab-pane" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                        <StakingFD />
                      </div>

                    </div>
                  </div>
                </div>
                {/* <h3 class="style__guide-title mb-20">Investment</h3> */}

              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
