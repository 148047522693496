import React, { useContext, useEffect, useState } from 'react'
import Dashbordheader from './Dashbordheader';
import Sidebar from './Sidebar';
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Tree from 'react-d3-tree';

export default function TreeView() {
  const { account, copyaddress } = useContext(ProjectContext)
  const [data, setdata] = useState({ name: "you" });
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [level, setlevel] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const CustomLabel = ({ nodeData }) => (
    <g>
      <text x="0" y="0" textAnchor="middle" fill="#fff">
        {nodeData.name}
      </text>
    </g>
  );
  const treeConfig = {
    nodeSize: { x: 800, y: 500 },
    separation: { siblings: 0.6, nonSiblings: 0.8 },
    initialDepth: 1,
    nodeSvgShape: {
      shape: "rect",
      shapeProps: {
        width: 0,
        height: 0,
        x: -20,
        y: 20,
        stroke: "#fff"
      }
    },
    allowForeignObjects: true,
    nodeLabelComponent: {
      render: <CustomLabel />,
      foreignObjectWrapper: {
        y: 0,
        x: 0,
      },
    },
    pathFunc: "step",
  };
  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g >
      <foreignObject x="-150" y="-210" width="300" height="480"
        style={{ cursor: 'pointer' }}>
        <div className="why-us__item text-center wow fadeIn" data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeIn', backgroundColor: "#0e0e0e" }}>
          <div className="why-us__icon" >
            <span><i className="fa fa-user" /></span>
          </div>
          <div className="why-us__content" >
            <h4 type="button" > <a className="pricing__btn-white fs-4" type='button' onClick={() => copyaddress(nodeDatum.address)}>{nodeDatum.name} <i className='fa fa-copy'></i></a></h4>
          </div>
          <div className="support__info"> 
            <ul className='d-flex justify-content-between'>
              <li>
                <div className="support__info-list">
                  <span>RD: ${nodeDatum.t_rd}</span>
                </div>
              </li>
              <li>
                <div className="support__info-list">
                  <span>FD: ${nodeDatum.t_fd}</span>
                </div>
              </li>

            </ul>
          </div>
          {nodeDatum.children?.length ?
            // <a className="bd-gradient-btn" type='button' onClick={toggleNode}><span><i className="fa-regular fa-angle-down fs-1" /></span></a>
            <a className="pricing__btn-white mt-4" type='button' onClick={toggleNode}><span><i className="fa-regular fa-angle-down" /></span></a>

            :
            ''}
        </div>

      </foreignObject>
    </g>
  );
  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "tree",
        // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        address: account,
        // address: "0xe7b485d74aafb65e8b8135dbcb226bdcdf414877",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  useEffect(() => {
    getData()
  }, [level])
  React.useEffect(() => {
    // const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();
    // setTranslate({
    //   x: dimensions.width / 2,
    //   y: dimensions.height / 4,
    // });
  }, []);

  return (
    <>
      <div>
        <Dashbordheader />
        <main>
          <div className="style__guide-area section-space">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-xl-2 col-lg-4">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="row justify-content-center wow fadeInUp vh-100" data-wow-delay=".6s" >
                  {isLoading ?
                    <div className='text-center'><h2>Loading...</h2></div>
                    :
                    <Tree
                      data={data}
                      orientation="vertical"
                      {...treeConfig}
                      translate={translate}
                      scaleExtent={{ min: 0.1, max: 1 }}
                      isInitialRenderForDataset={true}
                      enableLegacyTransitions={true}
                      renderCustomNodeElement={renderRectSvgNode}
                    />
                  }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
