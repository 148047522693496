import React from 'react'

export default function Coinsets() {
  return (
    <div>
      <section className="benefits__area o-xs section-space">
        <div className="container">
          <div className="row gy-50 align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="benefits__content-wrapper pe-0 wow fadeInLeft animated" data-wow-delay="0.3s">
                <div className="section__title-wrapper mb-35">
                  {/* <span className="section__subtitle uppercase mb-20">Your benefits</span> */}
                  <h3 className="section__title mb-20">Unlock multiple revenue streams
                  </h3>
                  <p className="mb-0 text-white">Receive up to 200% annual growth at maturity, accompanied by the benefits of additional income sources.
                  </p>
                </div>
                <h5>Annual growth
                </h5>
                <p>Smart DeFi World offers annual growth on deposits, which users will receive at maturity. This annual growth rate varies based on the deposit amount and maturity plan.
                </p>
                <h5 className='mt-3'>Referral Bonus</h5>
                <p>Earn up to ~20% bonus on all your direct referrals. Whether regular or fixed, you earn a bonus every time they make a deposit on Smart DeFi World. </p>

                <h5>Rank Income
                </h5>
                <p>Achieve higher ranks among 11 ranks by generating business through your referrals and unlock exclusive rewards with Rank Income.

                </p>
                <h5 className='mt-3'>Retirement Income
                </h5>
                <p>Achievers of the highest rank, Executive Manager House (EHM), are eligible to receive Retirement Income from a 2% monthly revenue allocation.</p>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="benefits__thumb  wow  fadeInRight animated" data-wow-delay="0.3s">
                <img src="assets/imgs/unlock_multiple_revenue_streams.png" alt="image not found" />
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}
