import React, { useState } from 'react';

export default function Rd() {
    const [plan, setPlan] = useState('');
    const [amount, setAmount] = useState('');
    const [reward, setReward] = useState(0);
    const [totalInvestment, setTotalInvestment] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const calculateReward = () => {
        if (!plan) {
            setErrorMessage('Please select a plan.');
            return;
        }

        let rewardAmount = 0;
        let investment = 0;
        const monthlyInvestment = parseFloat(amount);

        if (isNaN(monthlyInvestment) || monthlyInvestment <= 0) {
            setErrorMessage('Please enter a valid monthly investment amount.');
            return;
        }

        if (monthlyInvestment % 10 !== 0) {
            setErrorMessage('Please enter an amount that is a multiple of 10.');
            return;
        }

        if (plan === '1') {
            rewardAmount = monthlyInvestment * 12 + (monthlyInvestment * 2);
            investment = monthlyInvestment * 12;
        } else if (plan === '3') {
            rewardAmount = monthlyInvestment * 36 + (monthlyInvestment * 15);
            investment = monthlyInvestment * 36;
        } else if (plan === '5') {
            rewardAmount = monthlyInvestment * 60 + (monthlyInvestment * 60);
            investment = monthlyInvestment * 60;
        }
        setReward(rewardAmount);
        setTotalInvestment(investment);
        setErrorMessage('');
    };

    return (
        <>
            <div className="loan__form">
                <div>
                    {/* <h5 className='mb-5'>RD Calculator</h5> */}
                    <div className="contact__input-box mb-15 mb-20">
                        <div className="contact__input-title">
                            <label>Select RD Plan <span className='text-danger'>*</span></label>
                        </div>
                        <select className="nice-select contact__select rounded-pill" value={plan} onChange={(e) => setPlan(e.target.value)}>
                            <option className="option" value="">Select Plan</option>
                            <option value="1">One Year Plan</option>
                            <option value="3">Three Year Plan</option>
                            <option value="5">Five Year Plan</option>
                        </select>
                    </div>
                    <div className="contact__input-box mb-15 mb-20">
                        <label>
                            Enter Amount ($/month):
                        </label>
                        <input
                            type="number"
                            value={amount}
                            placeholder='0'
                            onChange={(e) => setAmount(e.target.value)}
                            step="10"
                        />
                    </div>
                    {errorMessage && <p style={{ color: '#000' }}>{errorMessage}</p>}
                    <br />
                    <div className="header__btn-wrap">
                        <button className="bd-gradient-btn" onClick={calculateReward}>Calculate Reward</button>
                    </div>

                </div>
            </div>
            <div className="loan__notice">
                <div className="loan__notice-content d-flex flex-wrap justify-content-between">
                    <div>
                        <h5>Invested:</h5>
                        {reward > 0 && (
                            <div>
                                <h3> ${amount}</h3>
                            </div>
                        )}
                    </div>
                    <div>
                        <h5>Total Investment:</h5>
                        {reward > 0 && (
                            <div>
                                <h3> ${totalInvestment}</h3>
                            </div>
                        )}
                    </div>
                    <div>
                        <h5>Claimed Amount:</h5>
                        {reward > 0 && (
                            <div>
                                <h3> ${reward}</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
