import React from 'react'

export default function Vision() {
    return (
        <>
            <section className="mission__area o-xs section-space">
                <div className="container">
                    <div className="row gy-50 align-items-center">
                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="mission__content wow fadeInRight animated" data-wow-delay=".6s">
                                <div className="section__title-wrapper">
                                    <span className="section__subtitle-7 mb-20">Our Mission</span>
                                    <h2 className="section__title mb-20">Vision </h2>
                                </div>
                                <p>Smart DeFi World aims to revolutionize the banking and finance sector with the
                                    innovative concept of decentralized banking. It leverages blockchain-powered
                                    applications, such as smart contracts, cryptographic assets, and advanced
                                    security mechanisms, to create a more transparent, secure, and efficient
                                    financial system. </p>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <img src="assets/imgs/vision.svg" alt="image not found" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
