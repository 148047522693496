import React, { useContext, useEffect, useState } from 'react'
import Rd from './Rd';
import FD from './FD';
import Dashbordheader from './Dashbordheader';
import Slader from './Sidebar';
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";

export default function Stake() {
    const { link_ref_address } = useParams();

    const { fdpkg, rdpkg, copyaddress,
        account,
        balance,
        rate,
        smuser,
        toastSuccess,
        library,
        dbuser,
        tokenrate, formatAddress,
        stakcontract,
        toastError,
        getudata,
        settings,
        esigner,
        ethers,
        getuserDB,
        connectMetamask,
    } = useContext(ProjectContext)

    const [showRd, setShowRd] = useState(true);
    const [tnc, settnc] = useState(false);
    const [spkg, setspkg] = useState(rdpkg !== null ? rdpkg[0] : null);
    const [spkgid, setspkgid] = useState(0);

    const [amount, setamount] = useState(0);
    const [total_tokens, settotal_tokens] = useState(0);
    const [trdone, settrdone] = useState(false);
    const [hash, sethash] = useState("");
    const [referral, setreferral] = useState("");
    function isDivisibleBy10(number) {
        return number % 10 === 0;
    }
    var navigate = useNavigate();

    const onDeposit = async () => {
        settrdone(true);
        sethash("");
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!amount || amount < 0) {
            toastError("Enter amount");
            settrdone(false);
            return;
        }
        if (showRd && dbuser !== null && dbuser.active_r_d) {
            toastError("Your one RD is running");
            settrdone(false);
            return
        }
        if (!showRd && dbuser !== null && dbuser.active_f_d) {
            toastError("Your one FD is running");
            settrdone(false);
            return
        }
        if (isDivisibleBy10(amount) === false) {
            toastError("Amount must be divisable by 10");
            settrdone(false);
            return
        }
        if (!tnc) {
            toastError("Accept Terms & Conditions");
            settrdone(false);
            return;
        }
        if (Number(amount) < (showRd ? 10 : 100)) {
            toastError(`Min ${showRd ? 10 : 100} required`);
            settrdone(false);
            return;
        }
        // if (Number(balance) < Number(total_tokens)) {
        //   toastError("Insufficient balance");
        //   return;
        // }
        if (!referral) {
            toastError("Enter Referral Address");
            settrdone(false);
            return;
        }
        var data = await getudata(referral);
        console.log('Data', data.data.data.active_rank);
        if (data.data.error) {
            toastError("Invalid Referral Address");
            settrdone(false);
            return "";
        }
        if (data.data.data.active_rank === true || data.data.data.agency === true) {
        } else {
            toastError("Referral Address not eligible");
            settrdone(false);
            return "";
        }
        // if (dbuser !== null && dbuser.last_staking > amount) {
        //     toastError(`Stake ${dbuser.last_staking} or more`);
        //     settrdone(false);
        //     return;
        // }
        let contract = new library.Contract(
            settings.contract,
            JSON.parse(settings.contract_abi),
            esigner
        );
        let token_contract = new library.Contract(
            settings.token,
            JSON.parse(settings.token_abi),
            esigner
        );
        const allowance = await token_contract.allowance(
            account,
            settings.contract
        );

        async function main() {
            // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
            //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
            //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
            //   );
            try {
                var stk = await contract.buypackage(
                    account,
                    referral,
                    spkgid,
                    ethers.utils.parseUnits(`${amount}`,8),
                    showRd ? 2 : 1,
                    ethers.utils.parseUnits(`${amount}`,8),
                );
                // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

                const receipt = await stk.wait();
                sethash(receipt.transactionHash);
                // sethash(randomHash);
                toastSuccess("Transaction done");
                await axios
                    .post(process.env.REACT_APP_BACKEND_LINK, {
                        method: "transaction",
                        submethod: "insert",
                        key: process.env.REACT_APP_KEY,
                        address: account,
                        ref_address: referral,
                        hash: receipt.transactionHash,
                        amount: amount,
                        token: total_tokens,
                        rate: tokenrate,
                        isrd: showRd,
                        pkg_id: spkg._id
                    })
                    .then((res) => {
                        // var dedata = decryptData(res.data);
                        if (res.data.error) {
                            return "";
                        }
                    });
                getuserDB();
                settrdone(false);
                navigate("/dashboard");

            } catch (error) {
                console.log("Error", error);
                toastError("Transaction declined");
                settrdone(false);
                return;
            }

        }
        // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
        if (Number(ethers.utils.parseUnits(`${allowance}`,8)) < total_tokens) {
            // if (false) {
            try {
                var approv = await token_contract.approve(
                    settings.contract,
                    ethers.utils.parseUnits("100000000",8)
                );
                const receipt = await approv.wait();
                console.log(receipt);
                toastSuccess("Token Approved");
                main();
            } catch (error) {
                console.log("error", error);
                toastError("Token not approved");
                settrdone(false);
                return;
            }
        } else {
            main();
        }
    };
    useEffect(() => {
        if (dbuser !== null) {
            if (dbuser.ref_address !== null) {
                // console.log("ref_addressref_addressref_address",dbuser.ref_address);
                setreferral(dbuser.ref_address);
            }
        } else {
            setreferral(link_ref_address);
        }
    }, [link_ref_address, dbuser, account]);
    useEffect(() => {
        settotal_tokens(amount / tokenrate);

    }, [amount, tokenrate]);
    function getDateAfterDays(days) {
        let today = new Date();

        let futureDate = new Date(today);
        futureDate.setDate(today.getDate() + days);

        let day = String(futureDate.getDate()).padStart(2, '0');
        let month = String(futureDate.getMonth() + 1).padStart(2, '0');
        let year = futureDate.getFullYear();

        return `${day}-${month}-${year}`;
    }


    // useEffect(() => {
    //     console.log(" onChange={() => setspkg(item)}", spkg);
    // }, [spkg])
    useEffect(() => {
        setspkg(showRd ? rdpkg[spkgid] : fdpkg[spkgid])
    }, [showRd])
    return (
        <>
            <Dashbordheader />
            <main>
                <div className="style__guide-area section-space pt-5">
                    <div className="container-fluid">
                        <div className="row g-5 justify-content-center">
                            <div className="col-xl-2 col-lg-2">
                                <Slader />
                            </div>
                            <div className="col-xl-9 col-lg-9">
                                <section className="loan__area section-space-top section-space-bottom" id='Calculator'>
                                    <div className="container">
                                        <div className='row justify-content-center'>
                                            <div className='col-lg-6 col-md-8 col-sm-12 col-12'>
                                                <div className="loan__wrapper wow fadeInUp" data-wow-delay=".3s">
                                                    <div className="loan__form-wrapper pe-0">
                                                        <div className="loan__from-title">
                                                            <h3 className='text-center'>Smart Defi</h3>
                                                        </div>

                                                        <div className='d-flex justify-content-center '>
                                                            <div className=" d-flex justify-content-between mb-5 bd-gradient-btn rounded-3">
                                                                <button className={showRd ? "active-text fs-20 text-break mx-2" : "fs-20 text-break mx-2"} onClick={() => setShowRd(!showRd)}>Regular Deposit</button>

                                                                <button className={!showRd ? "active-text fs-20 text-break mx-2" : "fs-20 text-break mx-2"} onClick={() => setShowRd(!showRd)}>Fixed Deposit</button>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="loan__form">
                                                            <div>
                                                                {/* <h5 className='mb-5'>RD Calculator</h5> */}
                                                                <div className="contact__input-box mb-15 mb-20">
                                                                    <div className="contact__input-title">
                                                                        <label>Select Goal<span className='text-danger'>*</span></label>
                                                                    </div>
                                                                    {showRd ?
                                                                        <div className="radio-inputs">
                                                                            {rdpkg.map((item, i) => {
                                                                                return (
                                                                                    <label className="radio">
                                                                                        <input type="radio" name="radiofd" defaultChecked={i === 0} onChange={() => { setspkg(item); setspkgid(i) }} />
                                                                                        <span className="name">{item.days / 360} Years</span>
                                                                                    </label>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                        :
                                                                        //fd
                                                                        <div className="radio-inputs">
                                                                            {fdpkg.map((item, i) => {
                                                                                return (
                                                                                    <label className="radio">
                                                                                        <input type="radio" name="radiofd" defaultChecked={i === 0} onChange={() => setspkg(item)} />
                                                                                        <span className="name">{item.days / 360} Years</span>
                                                                                    </label>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div >
                                                                    {/* <div className="contact__input-box mb-15 mb-20">
                                                                        <label>
                                                                            Enter Referral
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            // value={amount}
                                                                            placeholder='Enter Referral Address'
                                                                            onChange={(e) => setamount(e.target.value)}
                                                                        />
                                                                    </div> */}
                                                                    <label>
                                                                        Referral Address
                                                                    </label>
                                                                    {dbuser !== null ? (
                                                                        dbuser.ref_address !== null ? (
                                                                            <input
                                                                                className="contact__input-box mb-15 mb-20"
                                                                                type="text"
                                                                                value={referral}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <input
                                                                                    className="contact__input-box mb-15 mb-20"
                                                                                    type="text"
                                                                                    placeholder="Enter Referral Address"
                                                                                    value={referral}
                                                                                    onChange={(e) => { setreferral(e.target.value); }} /></>
                                                                        )
                                                                    ) : (
                                                                        <>

                                                                            <input
                                                                                className="contact__input-box mb-15 mb-20"
                                                                                type="text"
                                                                                placeholder="Enter Referral Address"
                                                                                value={referral}
                                                                                onChange={(e) => { setreferral(e.target.value); }} /></>
                                                                    )}
                                                                    <div className="contact__input-box mb-15 mb-20">
                                                                        <label>
                                                                            Enter Amount {showRd ? "($/month)" : ""}:
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            // value={amount}
                                                                            placeholder='0'
                                                                            onChange={(e) => setamount(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                    <div className="login__option mb-1 d-flex justify-content-between">
                                                                        <div className="login__remember">
                                                                            <p className='text-white'>Token</p>
                                                                        </div>
                                                                        <div className="login__forgot">
                                                                            <p className='text-white'>{total_tokens} {process.env.REACT_APP_TICKER} ($ {tokenrate} )</p>
                                                                        </div>
                                                                    </div>
                                                                    {showRd ?
                                                                        <div className="login__option mb-1 d-flex justify-content-between">
                                                                            <div className="login__remember">
                                                                                <p className='text-white'>Premiums</p>
                                                                            </div>
                                                                            <div className="login__forgot">
                                                                                <p className='text-white'>{spkg !== null ? ` ${spkg.installments} Months` : '0'}</p>

                                                                            </div>
                                                                        </div> : ''}
                                                                    {showRd ?
                                                                        <div className="login__option mb-1 d-flex justify-content-between">
                                                                            <div className="login__remember">
                                                                                <p className='text-white'>Reward Premiums</p>
                                                                            </div>
                                                                            <div className="login__forgot">
                                                                                <p className='text-white'>{spkg !== null ? ` ${spkg.reward} Months` : ''}</p>
                                                                            </div>
                                                                        </div> :
                                                                        <div className="login__option mb-1 d-flex justify-content-between">
                                                                            <div className="login__remember">
                                                                                <p className='text-white'>Reward</p>
                                                                            </div>
                                                                            <div className="login__forgot">
                                                                                <p className='text-white'>{spkg !== null ? ` ${spkg.reward} %` : ''}</p>
                                                                            </div>
                                                                        </div>}
                                                                    <div className="login__option mb-1 d-flex justify-content-between">
                                                                        <div className="login__remember">
                                                                            <p className='text-white'>Maturity Amount</p>
                                                                        </div>
                                                                        <div className="login__forgot">
                                                                            {showRd ?
                                                                                <p className='text-white'>$ {amount * spkg.maturity}</p> :
                                                                                <p className='text-white'>$ {Number(amount) + Number(amount * spkg.reward / 100)}</p>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="login__option mb-1 d-flex justify-content-between">
                                                                        <div className="login__remember">
                                                                            <p className='text-white'>Maturity Date</p>
                                                                        </div>
                                                                        <div className="login__forgot">
                                                                            <p className='text-white'>{getDateAfterDays(spkg.maturity_time)}</p>
                                                                        </div>
                                                                    </div>
                                                                    {hash ?
                                                                        <div className="login__option mb-2 d-flex justify-content-center">
                                                                            <div className="login__remember">
                                                                                <p className='text-white text-center'>Tnx hash</p>
                                                                                <p className='text-white text-break'>{formatAddress(hash)} <i className='fa fa-copy'></i></p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    <div className="login__option my-20 d-flex justify-content-center">
                                                                        <div className="login__remember">
                                                                            <input type="checkbox" id="trmscond" onChange={(event) => settnc(event.target.checked)} />
                                                                            <label htmlFor="trmscond"><Link to="/terms-and-service"> Terms & Conditions</Link></label>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    {trdone ? (
                                                                        <div className='text-center'>
                                                                            <img
                                                                                className=" bd-gradient-btn rounded-pill w-10"
                                                                                src="/assets/imgs/loader.gif"
                                                                                alt="loading"
                                                                                style={{ width: '100px' }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="header__btn-wrap d-flex justify-content-center">
                                                                            <button className="bd-gradient-btn rounded-pill" onClick={() => onDeposit()}>Invest</button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
