import React, { useContext, useEffect, useState } from 'react'
import table from './table.css';
import Dashbordheader from './Dashbordheader';
import Sidebar from './Sidebar';
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Coman/Pagination';
import Calculator from '../Home/Calculator';

export default function CalculaterDash() {
  return (
    <>
      <div>
        <Dashbordheader />
        <main>
          <div className="style__guide-area section-space">
            <div className="container-fluid">
              <div className="row g-5 justify-content-center">
                <div className="col-xl-2 col-lg-4">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-lg-8">
                  <Calculator />

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
