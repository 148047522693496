import React, { useContext, useState } from 'react'
import { ProjectContext } from '../../ProjectContext';
import { useNavigate } from 'react-router-dom';

export default function Overview() {
  const {  toastSuccess, setaccount } = useContext(ProjectContext)
  const [dname, setdname] = useState(null);

  var navigate = useNavigate();

  const godata = async () => {
    console.log("dname",dname);
    setaccount(dname)
    navigate("/dashboard")
    toastSuccess("Here you go!")
  }
  return (
    <>
      <div>
        {/* <Dashbordheader /> */}
        <main>
          <div className="style__guide-area section-space">
            <div className="container-fluid">
              <div className="row g-5 justify-content-center">

                <div className="col-xl-9 col-lg-8">
                  <h3 class="style__guide-title mb-20 text-center">Overview</h3>
                  <div className="row g-5 justify-content-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div
                        className="price__item wow fadeIn"
                      >
                        <div className="price__top">
                          <div className="contact__input">
                            <input
                              name="cSDCanyname"
                              onChange={(e) =>
                                setdname(e.target.value)
                              } placeholder="Enter address"
                            />
                          </div>

                          <div className="d-flex justify-content-center">
                            <button className="bd-gradient-btn btn-xs " onClick={() => godata()}>
                              Overview
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <br />

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
