import React, { useContext } from 'react';
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate } from 'react-router-dom';

export default function Dashbordheader() {
  const { showmenu,dbbalance, disconnectNow,setshowmenu } = useContext(ProjectContext)
  
  var navigate = useNavigate();

  const logout = () => {
      disconnectNow();
      navigate('/')
  }

  const toggleClass = () => {
    setshowmenu(!showmenu);
  };
  return (
    <div>
      <header>
        <div id="header-sticky" className="header__area">
          <div className="container-fluid">
            <div className="mega__menu-wrapper p-relative">
              <div className="header__main">
                <div className="header__left">
                  <div className="header__logo">
                    <Link to='/'>
                      <img
                        className="logo__white"
                        src="./../assets/imgs/smartdefi_logo.png"
                        alt="logo not found"
                      />
                    </Link>
                  </div>
                </div>
                <div className="header__right py-5 mt-5 mr-20">
                  <div className="header__action d-flex align-items-center ">
                    <div className='d-lg-block d-none'>
                      <div className="header__btn-wrap d-flex align-items-baseline ">
                        <p
                          className="bd-gradient-btn text-white"
                        >
                          $ {dbbalance}
                        </p>
                        <a
                          className="bd-gradient-btn"
                          href="#"
                          onClick={()=>logout()}
                        >
                          Logout
                          <span>
                            <i className="fa-regular fa-sign-out" />
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="header__hamburger ml-20 d-lg-none">
                      <div className="sidebar__toggle">
                        <a className="bar-icon" onClick={toggleClass}>
                          <span />
                          <span />
                          <span />
                        </a>
                      </div>
                    </div>
                    {/* for wp */}
                    <div className="header__hamburger ml-20 d-none">
                      <button
                        type="button"
                        className="hamburger-btn offcanvas-open-btn"
                      >
                        <span>01</span>
                        <span>01</span>
                        <span>01</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
