import React, { useContext, useState, useEffect} from 'react'
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate } from 'react-router-dom';
import Popover from "react-bootstrap/Popover";
import { OverlayTrigger } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
export default function Header() {
  const [mainMenu, setMainMenu] = useState(false);
  const { account, connectMetamask, copyaddress, formatAddress, disconnectNow } = useContext(ProjectContext);
  const navigate = useNavigate(); // React Router v6 hook for navigation
const popoverTop = (
        <Popover
            id="popover-positioned-top"
            className="popover"
            title="Popover top"
        >
            <strong>
                <a href="https://support.smartdefiworld.com/" target="_blank" rel="noreferrer" style={{ fontSize: "12px" }}>
                    Need <br /> Help ?
                </a>
            </strong>
        </Popover>
    );
  useEffect(() => {
    if (!account) {
      navigate('/');
    }
  }, [account, navigate]);
  return (
    <div>
      <div>
        <div className="fix">
          <div className={mainMenu ? "offcanvas__area info-open" : "offcanvas__area"}>
            <div className="offcanvas__wrapper">
              <div className="offcanvas__content">
                <div className="offcanvas__top d-flex justify-content-between align-items-center">
                  <div className="offcanvas__logo">
                    <Link to="/">
                      <img src="./../assets/imgs/smartdefi_logo.png" alt="logo not found" />
                    </Link>
                  </div>
                  <div className="offcanvas__close">
                    <button className="offcanvas-close-icon animation--flip" onClick={() => setMainMenu(false)}>
                      <span className="offcanvas-m-lines">
                        <span className="offcanvas-m-line line--1" /><span className="offcanvas-m-line line--2" /><span className="offcanvas-m-line line--3" />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="mobile-menu fix mean-container"><div className="mean-bar"><a href="#nav" className="meanmenu-reveal" style={{ right: '0px', left: 'auto', display: 'inline' }}><span><span><span /></span></span></a>
                  <nav className="mean-nav text-white">
                    <ul style={{ display: 'none' }}>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="">
                        <HashLink smooth to="/#Calculator">Calculator</HashLink>
                      </li>
                      <li>
                        <Link to="/transparency">Transparency</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                </div>
                <div className="offcanvas__social">
                  <ul>
                    <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="#"><i className="fab fa-twitter" /></a></li>
                    <li><a href="#"><i className="fab fa-youtube" /></a></li>
                    <li><a href="#"><i className="fab fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={mainMenu ? "offcanvas__overlay overlay-open" : "offcanvas__overlay"} />
        <div className="offcanvas__overlay-white" />
      </div>

      <header>
        <div id="header-sticky" className="header__area">
          <div className="container ">
            <div className="mega__menu-wrapper p-relative">
              <div className="header__main">
                <div className="header__left">
                  <div className="header__logo">
                    <Link to='/'>
                      <img src="./../assets/imgs/smartdefi_logo.png" alt="logo not found" />
                    </Link>
                  </div>
                </div>
                <div className="header__middle">
                  <div className="mean__menu-wrapper d-none d-lg-block">
                    <div className="main-menu">
                      <nav id="mobile-menu text-white">
                        <ul>
                          <li>
                            <Link to="/about">About Us</Link>
                          </li>
                          <li className="">
                            <HashLink smooth to="/#Calculator">Calculator</HashLink>
                          </li>
                          <li>
                            <Link to="/transparency">Transparency</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="header__right">
                  <div className="header__action d-flex align-items-center">
                    <div className="header__btn-wrap d-none d-xl-inline-flex">
                      {account ?
                        <Link to="/dashboard" className="bd-gradient-btn">
                          {/* <span>{formatAddress(account)}</span> */}
                          <span>Dashboard</span>
                        </Link> :
                        <a href="#" className="bd-gradient-btn" onClick={() => connectMetamask()}>
                          <span>Connect Wallet</span>
                        </a>}
                    </div>
                    <div className="header__hamburger ml-20 d-xl-none d-flex justify-content-between align-items-center">
                      <div className='p-3'>
                        {account ?
                          <>
                            <Link to="/dashboard">
                              {/* <span>{formatAddress(account)}</span> */}
                              <span><i className='fa fa-user fs-1 text-white me-3' /></span>
                            </Link>
                            <Link onClick={() => disconnectNow()}>
                              {/* <span>{formatAddress(account)}</span> */}
                              <span><i className='fa fa-sign-out fs-1 text-white' /></span>
                            </Link>
                          </>
                          :
                          <button onClick={() => connectMetamask()}>
                            <span><i className='fa fa-wallet fs-1 text-white' /></span>
                          </button>}
                      </div>
                      <div className="sidebar__toggle">
                        <a type='button' className="bar-icon" onClick={() => setMainMenu(true)}>
                          <span />
                          <span />
                          <span />
                        </a>
                      </div>
                    </div>
                    {/* for wp */}
                    <div className="header__hamburger ml-20 d-none">
                      <button type="button" className="hamburger-btn offcanvas-open-btn">
                        <span>01</span>
                        <span>01</span>
                        <span>01</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <OverlayTrigger trigger="click" placement="top" overlay={popoverTop}>
                    {/* <img width={90} height={80} src="assets/assets/img/chat-boat.png" alt="" className='sticky' style={{ cursor: 'pointer', float: "right", position: "absolute", bottom: "0", zIndex: "999", paddingRight: "10px", right: "0" }} /> */}
                    <a href="https://support.smartdefiworld.com" taget="_blank">
                    <img
                        width={70}
                        height={70}
                        src="./../assets/imgs/chatbot.svg"
                        alt=""
                        style={{ position: "fixed", right: "12px", bottom: "10%", zIndex: "999", width: "70px"}}
                        className="sticky"
                    /></a>
                </OverlayTrigger>
    </div>
  )
}