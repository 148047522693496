import React from 'react'

export default function AboutHero() {
    return (
        <>
            <div className="breadcrumb__area breadcrumb-space theme-bg- valign p-relative z-index-11 fix">
                <div className="breadcrumb__glow">
                    <div className="glow-1" />
                    <div className="glow-2" />
                </div>
                <div className="round__shape">
                    <img src="assets/imgs/shapes/cercle.png" alt="image not found" />
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <div className="breadcrumb__title-wraper">
                                <h2 className="breadcrumb__title wow fadeIn text-center" data-wow-delay=".5s">We are advocating decentralization in  <span className="gradient-text-3">banking & finance sector.</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
