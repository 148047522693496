import React, { useContext, useEffect, useState } from 'react'
import Dashbordheader from './Dashbordheader';
import Sidebar from './Sidebar';
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Agency() {
    const {
        account,
        rate,
        toastSuccess,
        library,
        dbuser,
        tokenrate,
        toastError,
        settings,
        esigner,
        ethers,
        getuserDB,
        connectMetamask,
    } = useContext(ProjectContext)
    var navigate = useNavigate();

    const [amount, setamount] = useState(5);
    const [total_tokens, settotal_tokens] = useState(0);
    const [trdone, settrdone] = useState(false);
    const [hash, sethash] = useState("");
    const [tnc, settnc] = useState(false);

    const onDeposit = async () => {
        settrdone(true);
        sethash("");
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!tnc) {
            toastError("Accept Terms & Conditions");
            settrdone(false);
            return;
        }

        let contract = new library.Contract(
            settings.contract,
            JSON.parse(settings.contract_abi),
            esigner
        );

        try {
            var stk = await contract.upgradetoagency(
                ethers.utils.parseUnits(`${total_tokens}`,8)
            );
            const receipt = await stk.wait();
            sethash(receipt.transactionHash);
            // sethash(randomHash);
            toastSuccess("Transaction done");
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "agency",
                    submethod: "insert",
                    key: process.env.REACT_APP_KEY,
                    address: account,
                    hash: receipt.transactionHash,
                    amount: amount,
                    token: total_tokens,
                    rate: tokenrate,
                })
                .then((res) => {
                    console.log("res", res.data);
                    // var dedata = decryptData(res.data);
                    if (res.data.error) {
                        return "";
                    }
                });
            getuserDB();
            settrdone(false);
            navigate("/dashboard");
        } catch (error) {
            console.log("Error", error);
            toastError("Transaction declined");
            settrdone(false);
            return;
        }
    };

    useEffect(() => {
        settotal_tokens(amount / tokenrate);
    }, [amount, tokenrate]);

    return (
        <>
            <Dashbordheader />
            <main>
                <div className="style__guide-area section-space pt-5">
                    <div className="container-fluid">
                        <div className="row g-5 justify-content-center">
                            <div className="col-xl-2 col-lg-2">
                                <Sidebar />
                            </div>
                            <div className="col-xl-9 col-lg-9">
                                <section className="loan__area section-space-top section-space-bottom" id='Calculator'>
                                    <div className="container">
                                        <div className='row justify-content-center'>
                                            <div className='col-lg-6 col-md-8 col-sm-12 col-12'>
                                                <div className="loan__wrapper wow fadeInUp" data-wow-delay=".3s">
                                                    <div className="loan__form-wrapper pe-0">
                                                        <div className="loan__from-title">
                                                            <h3 className='text-center'>Smart Defi Agency</h3>
                                                        </div>
                                                        <br />
                                                        <div className="contact__input-box mb-15 mb-20">
                                                            <label>
                                                                Agency's Fees ($)
                                                            </label>
                                                            <input
                                                                type="text"
                                                                value={amount}
                                                            />
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <p className='text-black'>Agency Fees</p>
                                                            <p className='text-black'>{total_tokens} SDC</p>
                                                        </div>
                                                        <div className="login__option my-20 d-flex justify-content-center">
                                                            <div className="login__remember">
                                                                <input type="checkbox" id="termscond" onChange={(event) => settnc(event.target.checked)}/>
                                                                <label htmlFor="termscond"><Link to="/terms-and-service"> Terms & Conditions</Link></label>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        {trdone ? (
                                                            <div className='text-center'>
                                                                <img
                                                                    className=" bd-gradient-btn rounded-pill w-10"
                                                                    src="/assets/imgs/loader.gif"
                                                                    alt="loading"
                                                                    style={{ width: '100px' }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="header__btn-wrap d-flex justify-content-center">
                                                                <button className="bd-gradient-btn rounded-pill" onClick={() => onDeposit()}>Purchase Agency</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
