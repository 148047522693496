import React from 'react'

export default function Investing() {
  return (
    <div>
      <div className="container">
        <div className="banner__content-wrapper p-relative z-index-11">
          <div className="banner__content wow fadeIn" data-wow-delay=".3s">
            <h3 className='text-center'><span className="gradient-text-1"></span>Smart DeFi World is the one-stop decentralized banking solution</h3>
          </div>
        </div>
        <section className="benefits__area section-space pt-0">
          <div className="row gy-5">
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="benefits__item-3 is-mainly-red text-center p-relative wow fadeIn" data-wow-delay=".3s">
                <div className="benefits__icon">
                  <img src="./assets/imgs/community_governance.png" alt="" srcset="" />
                </div>
                <div className="benefits__content-3">
                  <h3 className="benefits__title">Community Governance</h3>
                  <p>Users propose and vote on protocol changes to meet ever-evolving needs</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="benefits__item-3 is-mainly-red text-center p-relative wow fadeIn" data-wow-delay=".5s">
                <div className="benefits__icon">
                  <img src="./assets/imgs/innovative_financial_services.png" alt="" srcset="" />
                </div>
                <div className="benefits__content-3">
                  <h3 className="benefits__title">Innovative Financial Services</h3>
                  <p>Lending, borrowing, saving, and investing without intermediaries, hence zero
                    convenience fees.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="benefits__item-3 is-mainly-red text-center p-relative wow fadeIn" data-wow-delay=".7s">
                <div className="benefits__icon">
                  <img src="./assets/imgs/rultiple_revenue_streams.png" alt="" srcset="" />
                </div>
                <div className="benefits__content-3">
                  <h3 className="benefits__title">Multiple Revenue Streams</h3>
                  <p>Participants benefit not only from growth on deposit but also from referral
                    bonus, rank income, and more.</p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row gy-5">
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="benefits__item-3 is-mainly-red text-center p-relative wow fadeIn" data-wow-delay=".3s">
                <div className="benefits__icon">
                  <img src="./assets/imgs/secure.png" alt="" srcset="" />
                </div>
                <div className="benefits__content-3">
                  <h3 className="benefits__title">Secure</h3>
                  <p>Blockchain’s robust security mechanisms ensure safe transactions.</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="benefits__item-3 is-mainly-red text-center p-relative wow fadeIn" data-wow-delay=".5s">
                <div className="benefits__icon">
                  <img src="./assets/imgs/decentralized.png" alt="" srcset="" />
                </div>
                <div className="benefits__content-3">
                  <h3 className="benefits__title">Decentralized</h3>
                  <p>No central authority controls the workflow, ensuring true financial autonomy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <div className="benefits__item-3 is-mainly-red text-center p-relative wow fadeIn" data-wow-delay=".7s">
                <div className="benefits__icon">
                  <img src="./assets/imgs/foolproof.png" alt="" srcset="" />
                </div>
                <div className="benefits__content-3">
                  <h3 className="benefits__title">Fullproof</h3>
                  <p>Smart contracts handle peer-to-peer transactions efficiently.</p>
                </div>
              </div>
            </div>
          </div>
          <br />
        </section>
      </div>
    </div>
  )
}
