import React from 'react'

export default function Community() {
    return (
        <>
            <section className="why-us__area theme-bg-5 section-is-rounded section-space">
                <div className="container">
                    <div className="row justify-content-center">
                        
                        <div className="col-xxl-8 col-xl-6 col-lg-6">
                            <div className="efficient__content">
                                <div className="section__title-wrapper text-center section__title-space">
                                    <h2 className="section__title mb-20">Community</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-50">
                        <div className="col-xl-3 col-md-6 col-sm-6">
                            <a href="https://www.facebook.com/profile.php?id=61565305714775" title="FACEBOOK" target="_blank">
                            <div className="why-us__item text-center wow fadeIn" data-wow-delay=".3s">
                                <div className="why-us__icon">
                                    <span><i className="fab fa-facebook-f" /></span>
                                </div>
                                <div className="why-us__content">
                                    <h4>Facebook</h4>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-6">
                            <a href="https://www.youtube.com/channel/UC6xfhtGYy8ULv7x0PGj6_ig" title="YOUTUBE" target="_blank">
                            <div className="why-us__item text-center is-mainly-red wow fadeIn" data-wow-delay=".5s">
                                <div className="why-us__icon">
                                    <span><i className="fab fa-youtube" /></span>
                                </div>
                                <div className="why-us__content">
                                    <h4>Youtube</h4>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-6">
                            <a href="https://x.com/SDCProtocol" title="TWITTER" target="_blank">
                            <div className="why-us__item text-center is-mainly-blue wow fadeIn" data-wow-delay=".7s">
                                <div className="why-us__icon">
                                    <span><i className="fab fa-twitter" /></span>
                                </div>
                                <div className="why-us__content">
                                    <h4>Twitter</h4>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-6">
                            <a href="https://www.instagram.com/sdc_ecosystem/" title="INSTAGRAM" target="_blank">
                            <div className="why-us__item text-center is-mainly-blue wow fadeIn" data-wow-delay=".9s">
                                <div className="why-us__icon">
                                    <span><i className="fab fa-instagram" /></span>
                                </div>
                                <div className="why-us__content">
                                    <h4>Instagram</h4>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-xl-3 col-md-6 col-sm-6">
                            <a href="https://t.me/SDCEcosystem" title="TELEGRAM" target="_blank">
                            <div className="why-us__item text-center is-mainly-blue wow fadeIn" data-wow-delay=".9s">
                                <div className="why-us__icon">
                                    <span><i className="fab fa-telegram" /></span>
                                </div>
                                <div className="why-us__content">
                                    <h4>Telegram</h4>
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}