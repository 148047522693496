import React, { useContext, useEffect, useState } from 'react'
import table from './table.css';
import Dashbordheader from './Dashbordheader';
import Sidebar from './Sidebar';
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';

export default function Team() {
  const { showmenu, setshowmenu, account, dbuser, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [level, setlevel] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "level",
        // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        address: account,
        key: process.env.REACT_APP_KEY,
        level: level,
        page: currentPage,
        pageSize: 1000
      })
      .then((res) => {
        console.log("vvsdf", res.data);
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  useEffect(() => {
    getData()
  }, [level])

  return (
    <>
      <div>
        <Dashbordheader />
        <main>
          <div className="style__guide-area section-space">
            <div className="container-fluid">
              <div className="row g-5 justify-content-center">
                <div className="col-xl-2 col-lg-4">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="row justify-content-center wow fadeInUp" data-wow-delay=".6s">
                    <div className="col-xxl-12">
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                          <main>
                            <div className="container mt-4">
                              <div className="card">
                                <div className="row">
                                  {/* Grid column */}
                                  <div className="col-md-4">
                                    <footer>
                                      <section className="footer__area-common p-0">
                                        <div className="footer__newsletter">
                                          <div className="footer__newsletter-from mb-25">
                                            <div className="footer__newsletter-input p-relative">
                                              <input type="number" placeholder="Enter level" value={level}
                                                onChange={(e) => setlevel(e.target.value)} />
                                              <button className="footer__round-btn" onClick={() => getData()}><i className="fa-regular fa-angle-right" /></button>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </footer>
                                  </div>
                                  <div className="col-md-8">
                                    <h2 className="py-3 text-center font-bold font-up blue-text">Team</h2>
                                  </div>
                                </div>
                                <div className="card-body">
                                  {/* Grid row */}
                                  {/* Grid row */}
                                  {/*Table*/}
                                  <table className="table table-hover table-responsive mb-0">
                                    {/*Table head*/}
                                    <thead>
                                      <tr>
                                        <th scope="row"> #</th>
                                        <th className="th-lg">Leg</th>
                                        <th className="th-lg">Address(Username)</th>
                                        <th className="th-lg">Referral Address</th>
                                        <th className="th-lg">Team Business</th>
                                        <th className="th-lg">RD</th>
                                        <th className="th-lg">FD</th>
                                        <th className="th-lg">Datetime</th>
                                        <th className="th-lg">Rank</th>
                                        <th className="th-lg">Agency</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                      </tr>
                                      {!isLoading ? (
                                        !data.length ? (
                                          <tr className="text-center">
                                            <td className="text-center" colSpan={7}>
                                              <span className="w-100">No data found</span>
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {data.map((ele, i) => {
                                        return (
                                          <tr>
                                            <td>
                                              {i + 1}
                                            </td>
                                            <td>
                                              {dbuser !== null ? dbuser.a_leg_address === ele.address ? "A" : dbuser.b_leg_address === ele.address ? "B" : dbuser.c_leg_address === ele.address ? "C" : "O" : ""}
                                            </td>
                                            <td>
                                              <span onClick={() =>
                                                copyaddress(ele.address)
                                              }>
                                                Username:{ele.uname}
                                                <br />
                                                {formatAddress(ele.address)}  <i className="fa fa-copy"></i>
                                              </span>
                                            </td>
                                            <td>
                                              <span onClick={() =>
                                                copyaddress(ele.ref_address)
                                              }>
                                                {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i>
                                              </span>
                                            </td>
                                            <td>
                                              $ {ele.team_business} ({ele.team})
                                            </td>
                                            <td>
                                              $ {ele.t_rd}
                                            </td>
                                            <td>
                                              $ {ele.t_fd}
                                            </td>
                                            <td >
                                              {ele.createdAt}
                                            </td>
                                            <td >
                                              {ele.rank}
                                            </td>
                                            <td>
                                              {ele.agency ? (
                                                <div className="text-success">
                                                  Active
                                                </div>
                                              ) : (
                                                <div className="text-danger">
                                                  Not Active
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}

                                    </tbody>
                                    {/*Table body*/}
                                  </table>

                                </div>
                              </div>
                            </div>
                          </main>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
