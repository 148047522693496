import React from 'react'
import Header from '../Coman/Header'
import Footer from '../Coman/Footer'
import Hero from './Hero'
import Homeslider from './Homeslider'
import Investing from './Investing'
import Whyinvest from './Whyinvest'
import Coinsets from './Coinsets'
import Vault from './Vault'
import Investokens from './Investokens'
import Globaliat from './Globaliat'
import Growyour from './Growyour'
import Security from './Security'
import Stepfurther from './Stepfurther'
import Partners from './Partners'
import Exploremudrex from './Exploremudrex'
import Pricing from './Pricing'
import Statustics from './Statustics'
import Calculator from './Calculator'
import RdFd from './RdFd'
import Community from './Community'

export default function Index() {
  return (
    <>
      <div className="banner__area theme-bg-2 fix">
        <Header />
        <Hero />
        <Statustics />
        <Homeslider />
        <Investing />
        <Calculator />
        <Whyinvest />
        <br />
        <Vault />
        <Coinsets />
        {/* <Investokens/> */}
        <Globaliat />
        <br />
        {/* <Growyour /> */}
        <RdFd />
        <br />
        {/* <Security/> */}
        {/* <Stepfurther /> */}
        <br />
        <Partners />
        <br />

        <Community />
        <Exploremudrex />
        {/* <Pricing/> */}

        <Footer />
      </div>
    </>
  )
}
