import React from 'react'
import table from './table.css';
import Dashbordheader from './Dashbordheader';
import Sidebar from './Sidebar';

export default function Tbale() {
  return (
    <>
      <div>
        <Dashbordheader />
        <main>
          <div className="style__guide-area section-space">
            <div className="container-fluid">
              <div className="row g-5 justify-content-center">
                <div className="col-xl-2 col-lg-4">
                  <Sidebar />
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="row justify-content-center wow fadeInUp" data-wow-delay=".6s">
                    <div className="col-xxl-12">
                      <div className="solution__tab bd-tab">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"><span>01</span>RD Investment</button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><span>02</span>FD Investment</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xxl-12">
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                          <main>
                            <div className="container mt-4">
                              <div className="card">
                                <div className="row">
                                  {/* Grid column */}
                                  <div className="col-md-12">
                                    <h2 className="py-3 text-center font-bold font-up blue-text">Investment</h2>
                                  </div>
                                  {/* Grid column */}
                                </div>
                                <div className="card-body">
                                  {/* Grid row */}
                                  {/* Grid row */}
                                  {/*Table*/}
                                  <table className="table table-hover table-responsive mb-0">
                                    {/*Table head*/}
                                    <thead>
                                      <tr>
                                        <th scope="row">#</th>
                                        <th className="th-lg"><a>First Name</a></th>
                                        <th className="th-lg"><a href>Last Name</a></th>
                                        <th className="th-lg"><a href>Username</a></th>
                                        <th className="th-lg"><a href>Username</a></th>
                                        <th className="th-lg"><a href>Username</a></th>
                                        <th className="th-lg"><a href>Username</a></th>
                                      </tr>
                                    </thead>
                                    {/*Table head*/}
                                    {/*Table body*/}
                                    <tbody>
                                      <tr>
                                        <th scope="row">1</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">2</th>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>@fat</td>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>@fat</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">3</th>
                                        <td>Larry</td>
                                        <td>the Bird</td>
                                        <td>@twitter</td>
                                        <td>Larry</td>
                                        <td>the Bird</td>
                                        <td>@twitter</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">4</th>
                                        <td>Paul</td>
                                        <td>Topolski</td>
                                        <td>@P_Topolski</td>
                                        <td>Paul</td>
                                        <td>Topolski</td>
                                        <td>@P_Topolski</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">5</th>
                                        <td>Larry</td>
                                        <td>the Bird</td>
                                        <td>@twitter</td>
                                        <td>Larry</td>
                                        <td>the Bird</td>
                                        <td>@twitter</td>
                                      </tr>
                                    </tbody>
                                    {/*Table body*/}
                                  </table>
                                  {/*Bottom Table UI*/}
                                  <div className="d-flex justify-content-center">
                                    {/*Pagination */}
                                    <nav className="my-4 pt-2">
                                      <ul className="pagination pagination-circle pg-blue mb-0">
                                        {/*First*/}
                                        <li className="page-item disabled clearfix d-none d-md-block"><a className="page-link">First</a></li>
                                        {/*Arrow left*/}
                                        <li className="page-item disabled">
                                          <a className="page-link" aria-label="Previous">
                                            <span aria-hidden="true">«</span>
                                            <span className="sr-only">Previous</span>
                                          </a>
                                        </li>
                                        {/*Numbers*/}
                                        <li className="page-item active"><a className="page-link">1</a></li>
                                        <li className="page-item"><a className="page-link">2</a></li>
                                        <li className="page-item"><a className="page-link">3</a></li>
                                        <li className="page-item"><a className="page-link">4</a></li>
                                        <li className="page-item"><a className="page-link">5</a></li>
                                        {/*Arrow right*/}
                                        <li className="page-item">
                                          <a className="page-link" aria-label="Next">
                                            <span aria-hidden="true">»</span>
                                            <span className="sr-only">Next</span>
                                          </a>
                                        </li>
                                        {/*First*/}
                                        <li className="page-item clearfix d-none d-md-block"><a className="page-link">Last</a></li>
                                      </ul>
                                    </nav>
                                    {/*/Pagination */}
                                  </div>
                                  {/*Bottom Table UI*/}
                                </div>
                              </div>
                            </div>
                          </main>
                        </div>
                        <div className="tab-pane" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                          <main>
                            <div className="container mt-4">
                              <div className="card">
                                <div className="row">
                                  {/* Grid column */}
                                  <div className="col-md-12">
                                    <h2 className="py-3 text-center font-bold font-up blue-text">Investment</h2>
                                  </div>
                                  {/* Grid column */}
                                </div>
                                <div className="card-body">
                                  {/* Grid row */}
                                  {/* Grid row */}
                                  {/*Table*/}
                                  <table className="table table-hover table-responsive mb-0">
                                    {/*Table head*/}
                                    <thead>
                                      <tr>
                                        <th scope="row">#</th>
                                        <th className="th-lg"><a>First Name</a></th>
                                        <th className="th-lg"><a href>Last Name</a></th>
                                        <th className="th-lg"><a href>Username</a></th>
                                        <th className="th-lg"><a href>Username</a></th>
                                        <th className="th-lg"><a href>Username</a></th>
                                        <th className="th-lg"><a href>Username</a></th>
                                      </tr>
                                    </thead>
                                    {/*Table head*/}
                                    {/*Table body*/}
                                    <tbody>
                                      <tr>
                                        <th scope="row">1</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">2</th>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>@fat</td>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>@fat</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">3</th>
                                        <td>Larry</td>
                                        <td>the Bird</td>
                                        <td>@twitter</td>
                                        <td>Larry</td>
                                        <td>the Bird</td>
                                        <td>@twitter</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">4</th>
                                        <td>Paul</td>
                                        <td>Topolski</td>
                                        <td>@P_Topolski</td>
                                        <td>Paul</td>
                                        <td>Topolski</td>
                                        <td>@P_Topolski</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">5</th>
                                        <td>Larry</td>
                                        <td>the Bird</td>
                                        <td>@twitter</td>
                                        <td>Larry</td>
                                        <td>the Bird</td>
                                        <td>@twitter</td>
                                      </tr>
                                    </tbody>
                                    {/*Table body*/}
                                  </table>
                                  {/*Bottom Table UI*/}
                                  <div className="d-flex justify-content-center">
                                    {/*Pagination */}
                                    <nav className="my-4 pt-2">
                                      <ul className="pagination pagination-circle pg-blue mb-0">
                                        {/*First*/}
                                        <li className="page-item disabled clearfix d-none d-md-block"><a className="page-link">First</a></li>
                                        {/*Arrow left*/}
                                        <li className="page-item disabled">
                                          <a className="page-link" aria-label="Previous">
                                            <span aria-hidden="true">«</span>
                                            <span className="sr-only">Previous</span>
                                          </a>
                                        </li>
                                        {/*Numbers*/}
                                        <li className="page-item active"><a className="page-link">1</a></li>
                                        <li className="page-item"><a className="page-link">2</a></li>
                                        <li className="page-item"><a className="page-link">3</a></li>
                                        <li className="page-item"><a className="page-link">4</a></li>
                                        <li className="page-item"><a className="page-link">5</a></li>
                                        {/*Arrow right*/}
                                        <li className="page-item">
                                          <a className="page-link" aria-label="Next">
                                            <span aria-hidden="true">»</span>
                                            <span className="sr-only">Next</span>
                                          </a>
                                        </li>
                                        {/*First*/}
                                        <li className="page-item clearfix d-none d-md-block"><a className="page-link">Last</a></li>
                                      </ul>
                                    </nav>
                                    {/*/Pagination */}
                                  </div>
                                  {/*Bottom Table UI*/}
                                </div>
                              </div>
                            </div>
                          </main>
                        </div>

                      </div>
                    </div>
                  </div>
                  {/* <h3 class="style__guide-title mb-20">Investment</h3> */}

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
