import React from 'react'
import AboutHero from './AboutHero'
import SmartDefi from './SmartDefi'
import Header from '../Coman/Header'
import Footer from '../Coman/Footer'
import Mission from './Mission'
import Vision from './Vision'

export default function About() {
    return (
        <>
            <Header />
            <AboutHero />
            <br />
            <SmartDefi />
            <br />
            <Vision />
            <Mission />
            <Footer />
        </>
    )
}
