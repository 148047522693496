import React from 'react'

export default function Vault() {
  return (
    <div>
      <section className="benefits__area o-xs section-space">
        <div className="container">
          <div className="row gy-50 align-items-center">
            <div className="col-xxl-5 col-xl-5 col-lg-5">
              <div className="benefits__thumb  wow  fadeInRight animated" data-wow-delay="0.3s">
                <img src="assets/imgs/flexible_deposits.png" alt="image not found" />
              </div>
            </div>
            <div className="col-xxl-1 col-xl-1 col-lg-1"></div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="benefits__content-wrapper pe-0 wow fadeInLeft animated" data-wow-delay="0.3s">
                <div className="section__title-wrapper mb-35">
                  {/* <span className="section__subtitle uppercase mb-20">Your benefits</span> */}
                  <h3 className="section__title mb-20">Flexible deposits, flexible timing, and flexible Premiums
                  </h3>
                  <p className="">Users can choose to deposit any amount they want to join Smart DeFi World. We offer two types of deposits: Fixed and Regular.</p>
                  <h5>Fixed Deposits</h5>
                  <p>Make fixed deposits by simply choosing the amount and term. The capital, along with annual growth, will be released at maturity.</p>

                  <h5 className='mt-3'>Regular Deposits</h5>
                  <p>Regular deposits involve monthly Premiums for a fixed term, with the capital and growth being released at maturity.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
