import React from 'react'

export default function Mission() {
    return (
        <>
            <section className="mission__area o-xs section-space">
                <div className="container">
                    <div className="row gy-50 align-items-center">
                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <img src="assets/imgs/mission_svg.svg" alt="image not found" />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="mission__content wow fadeInRight animated" data-wow-delay=".6s">
                                <div className="section__title-wrapper">
                                    <span className="section__subtitle-7 mb-20">Our Mission</span>
                                    <h2 className="section__title mb-20">Mission</h2>
                                </div>
                                <p>The mission of Smart DeFi World is to decentralize the banking sector and
                                    transform parabanking operations. By democratizing finance, Smart DeFi World
                                    empowers individuals to benefit from an enhanced and improved financial
                                    ecosystem, ensuring broader access to financial services and opportunities
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
