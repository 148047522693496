import React, { useContext } from 'react';
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate } from 'react-router-dom';

export default function Sidebar() {
  const { showmenu, setshowmenu, dbbalance, disconnectNow } = useContext(ProjectContext)

  var navigate = useNavigate();

  const logout = () => {
    disconnectNow();
    navigate('/')
  }
  return (
    <div>
      <div className="widget-sidebar bd-sticky-top d-lg-block d-md-block d-sm-none d-none">
        <div className="content-item-content">
          <div className="widget-details">
            <nav className="onepagenav">
              {/* <Link to=""><img src="./assets/imgs/smartdefi_logo.png" style={{ width: "80%" }} alt="" /></Link> */}
              <ul className="mainmenu rbt-course-details-list-wrapper pt-100 text-start">
                <li className="current">
                  <Link to="/dashboard" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/dashboard.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="/staking" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/investment.svg" className='side-icon' alt="" />&nbsp;&nbsp;
                    <span>Investment</span>
                  </Link>
                </li>
                <li>
                  <Link to="/team" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/team.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Team</span>
                  </Link>
                </li>
                <li>
                  <Link to="/tree" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/team.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Tree</span>
                  </Link>
                </li>
                <li>
                  <Link to="/level-reward" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/level_reward.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Level Reward</span>
                  </Link>
                </li>
                <li>
                  <Link to="/rank-reward" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/position_reward.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Position reward</span>
                  </Link>
                </li>
                <li>
                  <Link to="/retirement-reward" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/retirement_reward.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Retirement  Reward</span>
                  </Link>
                </li>
                <li>
                  <Link to="/withdrawal" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/claim.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Claim</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/table" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/investment.png" className='side-icon' alt="" />&nbsp;&nbsp;<span>RD Report</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/agency-details" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/agency_details.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Agency Details</span>
                  </Link>
                </li>
                <li>
                  <Link to="/calculater" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/calculater.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Calculater</span>
                  </Link>
                </li>
                <li>
                  <a href="/assets/smart_defi_presentation.pdf" target='_blank' className="d-flex justify-content-start text-start align-items-center" >
                    <img src="./../assets/imgs/presentation.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Presentation</span>
                  </a>
                </li>
              </ul>
            </nav>
            <ul className="mainmenu rbt-course-details-list-wrapper footer-menu pb-100">
              <hr />
              <br />
              <li className="current">
                <Link to="/profile" className="d-flex justify-content-start text-start align-items-center">
                  <img src="./../assets/imgs/profile.svg" className='side-icon' alt="" />&nbsp;&nbsp; <span>Profile</span>
                </Link>
              </li>
              <li className="current">
                <Link to="/nominee" className="d-flex justify-content-start text-start align-items-center">
                  <img src="./../assets/imgs/nominee.svg" className='side-icon' alt="" />&nbsp;&nbsp; <span>Nominee</span>
                </Link>
              </li>
              <li>
                <Link to="/" className="d-flex justify-content-start text-start align-items-center" onClick={logout}>
                  <img src="./../assets/imgs/logout.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* mobile header */}
      <div className={!showmenu ? "d-none" : "widget-sidebar bd-sticky-top d-lg-none d-md-none d-sm-none d-block"}>
        <div className="content-item-content">
          <div className="widget-details">
            <nav className="onepagenav">
              {/* <Link to=""><img src="./../assets/imgs/smartdefi_logo.png" style={{ width: "80%" }} alt="" /></Link> */}
              <ul className="mainmenu rbt-course-details-list-wrapper pt-100 text-start">
                <li className="current">
                  <Link to="/dashboard" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/dashboard.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="/staking" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/investment.svg" className='side-icon' alt="" />&nbsp;&nbsp;
                    <span>Investment</span>
                  </Link>
                </li>
                <li>
                  <Link to="/team" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/team.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Team</span>
                  </Link>
                </li>
                <li>
                  <Link to="/level-reward" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/level_reward.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Level Reward</span>
                  </Link>
                </li>
                <li>
                  <Link to="/rank-reward" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/position_reward.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Position reward</span>
                  </Link>
                </li>
                <li>
                  <Link to="/retirement-reward" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/retirement_reward.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Retirement  Reward</span>
                  </Link>
                </li>
                <li>
                  <Link to="/withdrawal" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/claim.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Claim</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/table" className="d-flex justify-content-start text-start align-items-center">
                    <img src="./../assets/imgs/investment.png" className='side-icon' alt="" />&nbsp;&nbsp;<span>RD Report</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/agency-details" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/agency_details.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Agency Details</span>
                  </Link>
                </li>
                <li>
                  <Link to="/calculater" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                    <img src="./../assets/imgs/calculater.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Calculater</span>
                  </Link>
                </li>
                <li>
                  <a href="./../assets/smart_defi_presentation.pdf" target='_blank' className="d-flex justify-content-start text-start align-items-center" download>
                    <img src="./../assets/imgs/presentation.svg" className='side-icon' alt="" />&nbsp;&nbsp;<span>Presentation</span>
                  </a>
                </li>
              </ul>
            </nav>
            <ul className="mainmenu rbt-course-details-list-wrapper footer-menu pb-100">
              <hr />
              <br />
              <li className="current">
                <Link to="/profile" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                  <i class="fa fa-user" aria-hidden="true" />&nbsp;&nbsp; <span>Profile</span>
                </Link>
              </li>
              <li className="current">
                <Link to="/dashboard" className="d-flex justify-content-start text-start align-items-center" onClick={() => setshowmenu(!showmenu)}>
                  <i class="fa fa-user" aria-hidden="true" />&nbsp;&nbsp; <span>Nominee</span>
                </Link>
              </li>
              {/* <li>
                <Link to="" className="d-flex justify-content-start text-start align-items-center">
                  <i class="fa fa-cog" aria-hidden="true" />&nbsp;&nbsp; <span>Setting</span>
                </Link>
              </li> */}
              <li>
                <Link to="/" className="d-flex justify-content-start text-start align-items-center" onClick={logout}>
                  <i class="fa fa-sign-out" aria-hidden="true" />&nbsp;&nbsp;<span>Logout</span>
                </Link>
              </li>
            </ul>
            <div className="header__btn-wrap d-flex align-items-baseline ">
              <p
                className="bd-gradient-btn text-white"
              >
                ${dbbalance}
              </p>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}