import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function Whyinvest() {
  return (
    <div>
      <div className="container">
        <div className="banner__content-wrapper" id='Benefits'>
          {/* <span className="banner__sbutitle uppercase">Explore finwise now</span> */}
          <h2 className='text-center mb-4'><span className="gradient-text-1">Decentralized
          </span> Banking <br /> vs. <br /> <span className="gradient-text-1"> Traditional </span> Banking
          </h2>
          <p className='text-white mt-2 text-center'>The need for decentralized banking has emerged as inefficiencies of
            traditional banking remain unsolved in this digitized world. Decentralized
            banking fulfills every need that the current finance landscape requires.
          </p>
        </div>
        <Carousel
          showThumbs={false}
          showStatus={false}
          emulateTouch
          // autoPlay
          useKeyboardArrows
          transitionTime={900}
          // axis="vertical"
          selectedItem={0}
          infiniteLoop={true}
        >

          <div>
            <div className="features__box">
              <div className="features__active">
                <div className="features__box-inner">
                  <div className="row align-items-center gy-50">
                    <div className="col-xxl-6 col-xl- col-lg-6">
                      <div className="features__thumb">
                        <img src="assets/imgs/transparency.png" alt="image not found" />
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                      <div className="features__content pe-0">
                        <h2>1</h2>
                        <h3 className='text-center'>Control and Governance</h3>
                        <ul className='text-start' style={{ listStyle: "none" }}>
                          <li>
                            <h5 className='text-black fw-bold'>Traditional Banking:</h5>
                            <p className='text-start text-white'>Centralized control by banks and financial
                              institutions, which manage and oversee transactions and
                              services.</p>
                          </li>
                          <li>
                            <h5 className='text-black fw-bold'>Decentralized Banking:</h5>
                            <p className='text-start text-white'>Operates on a blockchain with
                              distributed control, where no single entity has overarching control
                              and users participate in governance through consensus
                              mechanisms.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div>
            <div className="features__box">
              <div className="features__active">
                <div className="features__box-inner">
                  <div className="row align-items-center gy-50">
                    <div className="col-xxl-6 col-xl- col-lg-6">
                      <div className="features__thumb">
                        <img src="assets/imgs/centralization_decentralization.png" alt="image not found" />
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl- col-lg-6">
                      <div className="features__content pe-0">
                        <h2>2</h2>
                        <h3 className='text-center'>Intermediaries</h3>
                        <ul className='text-start' style={{ listStyle: "none" }}>
                          <li>
                            <h5 className='text-black fw-bold'>Traditional Banking:</h5>
                            <p className='text-start text-white'>Relies on intermediaries for processing
                              transactions, loans, and other financial services, often leading to
                              higher fees and slower processes</p>
                          </li>
                          <li>
                            <h5 className='text-black fw-bold'>Decentralized Banking:</h5>
                            <p className='text-start text-white'>Eliminates intermediaries by using
                              smart contracts, enabling peer-to-peer transactions that are
                              faster and often less expensive.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="features__box">
              <div className="features__active">
                <div className="features__box-inner">
                  <div className="row align-items-center gy-50">
                    <div className="col-xxl-6 col-xl- col-lg-6">
                      <div className="features__thumb">
                        <img src="assets/imgs/management_maintenance.png" alt="image not found" />
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl- col-lg-6">
                      <div className="features__content pe-0">
                        <h2>3</h2>
                        <h3 className='text-center'>Transparency</h3>
                        <ul className='text-start' style={{ listStyle: "none" }}>
                          <li>
                            <h5 className='text-black fw-bold'>Traditional Banking:</h5>
                            <p className='text-start text-white'>Limited transparency, with customers often
                              having restricted access to how their funds are managed and
                              where fees come from.</p>
                          </li>
                          <li>
                            <h5 className='text-black fw-bold'>Decentralized Banking:</h5>
                            <p className='text-start text-white'>Full transparency as all transactions are
                              recorded on a public ledger, accessible to anyone, ensuring a
                              high level of visibility and accountability.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="features__box">
            <div className="features__active">
              <div className="features__box-inner">
                <div className="row align-items-center gy-50">
                  <div className="col-xxl-6 col-xl- col-lg-6">
                    <div className="features__thumb">
                      <img src="assets/imgs/accessibility.png" alt="image not found" />
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl- col-lg-6">
                    <div className="features__content pe-0">
                      <h2>4</h2>
                      <h3 className='text-center'>Accessibility</h3>
                      <ul className='text-start' style={{ listStyle: "none" }}>
                        <li>
                          <h5 className='text-black fw-bold'>Traditional Banking:</h5>
                          <p className='text-start text-white'>Access to services can be restricted by
                            geographic location, documentation requirements, and banking
                            hours.</p>
                        </li>
                        <li>
                          <h5 className='text-black fw-bold'>Decentralized Banking:</h5>
                          <p className='text-start text-white'>Global accessibility 24/7, allowing
                            anyone with an internet connection to access financial services
                            regardless of location or traditional banking constraints</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>

    </div>
  )
}
