/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pages,
}) => {
  return (
    <>
      <div className="d-flex justify-content-end">
        <nav>
          <ul className="pagination">
            <li className={` page-item ${currentPage === 1 ? "active" : ""}`}>
              <a
                href="#"
                className="page-link"
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1 ? true : false}
              >
                {"<<"}
              </a>
            </li>
            {currentPage - 2 > 0 ? (
              <li className=" page-item">
                <a
                  href="#"
                  className="page-link"
                  onClick={() => setCurrentPage((prev) => prev - 2)}
                >
                  {currentPage - 2}
                </a>
              </li>
            ) : (
              ""
            )}
            {currentPage - 1 > 0 ? (
              <li className=" page-item">
                <a
                  href="#"
                  className="page-link"
                  onClick={() => setCurrentPage((prev) => prev - 1)}
                >
                  {currentPage - 1}
                </a>
              </li>
            ) : (
              ""
            )}
            <li className=" page-item active">
              <a href="#" className="page-link" disabled={true}>
                {currentPage}
              </a>
            </li>
            {currentPage + 1 <= pages ? (
              <li className=" page-item">
                <a
                  href="#"
                  className="page-link"
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                  {currentPage + 1}
                </a>
              </li>
            ) : (
              ""
            )}
            {currentPage + 2 <= pages ? (
              <li className=" page-item">
                <a
                  href="#"
                  className="page-link"
                  onClick={() => setCurrentPage((prev) => prev + 2)}
                >
                  {currentPage + 2}
                </a>
              </li>
            ) : (
              ""
            )}
            <li
              className={` page-item ${currentPage === pages ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => setCurrentPage(pages)}
                disabled={currentPage === pages ? true : false}
              >
                {">>"}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Pagination;
