import React from 'react'

export default function Globaliat() {
  return (
    <div>
      <section>
        <div className="container">
          <div className="features__box">
            <div className="features__active">
              <div className="features__box-inner">
                <div className="row gy-50 align-items-center">
                  <div className="col-xxl-6 col-xl-6 col-lg-6">
                    <div className="benefits__content-wrapper wow fadeInLeft animated" data-wow-delay="0.3s">
                      <div className="section__title-wrapper mb-35">
                        {/* <span className="section__subtitle uppercase mb-20">Your benefits</span> */}
                        <h2 className="section__title mb-20 text-white">SDC – The currency for Smart DeFi World</h2>
                        <p className='text-white'>Smart DeFi World uses SDC as the currency and unit of value for exchange. All financial operations, including deposits, withdrawals, and rewards, are processed using SDC. It is the native token for the Smart Digital Connect ecosystem.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6">
                    <div className="benefits__thumb  wow  fadeInRight animated" data-wow-delay="0.3s">
                      <img src="assets/imgs/sdc-the-currency-for-smart-defi.png" alt="image not found" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
